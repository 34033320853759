import * as d3 from 'd3'
import React, { useEffect, useMemo, useRef } from 'react'
import { JobRange } from './types'
import XAxis from './XAxis'

const margins = {
  left: 40,
  right: 20,
  top: 20,
  bottom: 20
}

type ContextGraphProps = {
  jobs: JobRange[]
  width: number
  height: number
  domain: [Date, Date]
  zoom: [Date, Date]
  setZoom: (zoom: [Date, Date]) => void
}

const ContextGraph = (props: ContextGraphProps): JSX.Element => {
  const { domain, setZoom, jobs } = props
  const width = props.width - margins.left - margins.right
  const height = props.height - margins.top - margins.bottom
  const brushRef = useRef<SVGSVGElement>(null)
  const xScale = useMemo(() => d3.scaleTime().domain(domain).range([0, width]), [domain, width])
  const brush = useMemo(() => {
    return d3.brushX().extent([
      [0, 0],
      [width, height]
    ])
  }, [height, width])

  useEffect(() => {
    brush.on('brush', (event) => {
      if (!event.sourceEvent) return
      if (!event.selection) return
      setZoom(event.selection.map(xScale.invert) as [Date, Date])
    })
  }, [brush, setZoom, xScale])

  useEffect(() => {
    if (!brushRef.current) return
    d3.select(brushRef.current)
      .call(brush as any)
      .select('.selection')
      .attr('fill', '#e6f3ff')
      .attr('stroke', '#38d')
      .attr('stroke-width', '1')
  }, [brush, brushRef])

  useEffect(() => {
    if (!brushRef.current) return
    d3.select(brushRef.current).call(brush.move as any, props.zoom.map(xScale))
  }, [brush.move, props.zoom, xScale])

  return (
    <g transform={`translate(${margins.left}, ${margins.top})`}>
      <clipPath id='context-clip'>
        <rect width={width} height={height} />
      </clipPath>
      <g stroke='red' fill='red' opacity='0.5' clipPath='url(#context-clip)'>
        {jobs.map((job) => (
          <rect
            x={xScale(job.start)}
            y={0}
            width={xScale(job.end) - xScale(job.start)}
            height={height}
            key={job.name}
          />
        ))}
      </g>
      <g className='brush' ref={brushRef} />
      <g transform={`translate(0, ${height})`}>
        <XAxis xScale={xScale} zoom={domain} height={height} />
      </g>
    </g>
  )
}

export default ContextGraph

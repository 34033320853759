import React from 'react'

import DeviceHistoryView from './DeviceHistoryView'
import useDeviceHistory from './useDeviceHistory'

const DeviceHistory = (): JSX.Element => {
  const state = useDeviceHistory()

  return <DeviceHistoryView {...state} />
}

export default DeviceHistory

import React, { FunctionComponent } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import './jobMap.less'
import Map from '../../../../components/map'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { GeoData } from '../../../../components/map/mapType'

interface JobMapViewProps {
  job?: Job
  geoData: GeoData[]
}

const JobMapView: FunctionComponent<JobMapViewProps> = (props: JobMapViewProps) => {
  return (
    <div className='job-map'>
      <ContentLoader loading={!props.job}>
        <Map geoData={props.geoData.length ? props.geoData : undefined} />
      </ContentLoader>
    </div>
  )
}

export default JobMapView

import useTable from '../../../../components/useTable'
import { generatePath, useHistory } from 'react-router-dom'
import { useFindQuery } from '../../../../query'
import { ReactText, useCallback } from 'react'
import qs from 'qs'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { appRoutes } from '../../../../containers/Router/routes'
import { WorkerListViewProps } from './WorkerListView'

const useWorkerList = (args: { manager: boolean } = { manager: false }): WorkerListViewProps => {
  const { query, currentPage, onTableChange } = useTable<Worker>()

  const history = useHistory()
  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  const handleOnRowClick = useCallback((worker: Worker): void => {
    history.push(generatePath(appRoutes.reportsShow.routes.managerReport.path, { id: worker._id }))
  }, [])

  const { data: workersRes, status } = useFindQuery('workers', {
    $sort: { start: '-1' },
    manager: args.manager,
    ...query
  })

  return {
    currentPage,
    handleFilter,
    onTableChange,
    workers: workersRes?.data,
    loading: status === 'loading',
    total: workersRes?.total || 0,
    handleOnRowClick
  }
}

export default useWorkerList

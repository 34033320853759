import React, { FunctionComponent, useContext } from 'react'
import { Layout, Menu, Drawer, Spin, Avatar } from 'antd'
import {
  DashboardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  SmileOutlined,
  TabletOutlined,
  EnvironmentOutlined,
  OrderedListOutlined,
  BuildOutlined,
  UsergroupAddOutlined,
  GlobalOutlined,
  AreaChartOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import logo from '../../images/logo365x139.png'

import './appLayout.less'
import { appRoutes } from '../../containers/Router/routes'
import { LocalizationContext } from '../../providers/LocalizationProvider'

const { Header, Content, Sider, Footer } = Layout

interface LeftMenuContentProps extends AppLayoutViewProps {}

const LeftMenuContent: FunctionComponent<LeftMenuContentProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  const pathRegex = /^\/[^\/]*\/[^\/]*/

  const selectedKey = props.leftMenuSelectedKey
    ? props.leftMenuSelectedKey.match(pathRegex)
    : undefined

  return (
    <Menu theme='dark' selectedKeys={selectedKey?.[0] ? [selectedKey?.[0]] : undefined}>
      <Menu.Item key={appRoutes.home.path}>
        <Link to={appRoutes.home.path}>
          <DashboardOutlined />
          <span>{t(layouts.dashboard)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.mainMap.path}>
        <Link to={appRoutes.mainMap.path}>
          <GlobalOutlined />
          <span>{t(layouts.map)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.jobs.path}>
        <Link to={appRoutes.jobs.path}>
          <OrderedListOutlined />
          <span>{t(layouts.jobs)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.tasks.path}>
        <Link to={appRoutes.tasks.path}>
          <BuildOutlined />
          <span>{t(layouts.tasks)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.workers.path}>
        <Link to={appRoutes.workers.path}>
          <SmileOutlined />
          <span>{t(layouts.workers)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.devices.path}>
        <Link to={appRoutes.devices.path}>
          <TabletOutlined />
          <span>{t(layouts.devices)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.locations.path}>
        <Link to={appRoutes.locations.path}>
          <EnvironmentOutlined />
          <span>{t(layouts.locations)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.users.path}>
        <Link to={appRoutes.users.path}>
          <UserOutlined />
          <span>{t(layouts.users)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.groups.path}>
        <Link to={appRoutes.groups.path}>
          <UsergroupAddOutlined />
          <span>{t(layouts.groups)}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={appRoutes.reportsShow.path}>
        <Link to={appRoutes.reportsShow.path}>
          <AreaChartOutlined />
          <span>{t(layouts.reports)}</span>
        </Link>
      </Menu.Item>
    </Menu>
  )
}

interface LeftMenuTitleProps extends AppLayoutViewProps {}

const LeftMenuTitle = (props: LeftMenuTitleProps): JSX.Element => (
  <>
    <Link to={appRoutes.home.path}>
      <img
        className='app-layout__logo'
        src={logo} // TODO: change for reverse colors
        alt='Workwy logo'
        style={{
          display: props.leftMenuCollapsed ? 'none' : 'inline-block'
        }}
      />
      <img
        className='app-layout__logo'
        src={logo} // TODO: change for smaller icon
        alt='Workwy logo'
        style={{
          display: props.leftMenuCollapsed ? 'inline-block' : 'none'
        }}
      />
    </Link>
  </>
)

interface AppLayoutViewProps {
  leftMenuSelectedKey?: string
  handleDrawerOpen: (e: any) => void
  handleOnCollapse: (e: any) => void
  leftMenuDrawerOpen: boolean
  leftMenuCollapsed: boolean
  children?: React.ReactNode
  authenticationInProgress: boolean
}

const AppLayoutView: FunctionComponent<AppLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <Layout className='app-layout' style={{ minHeight: '100vh' }}>
      <Drawer
        className='app-layout__show-above-md app-layout_drawer'
        title={<LeftMenuTitle {...props} />}
        placement='left'
        closable={false}
        onClose={props.handleDrawerOpen}
        visible={props.leftMenuDrawerOpen}
      >
        <LeftMenuContent {...props} />
      </Drawer>
      <Sider
        collapsible
        collapsed={props.leftMenuCollapsed}
        trigger={null}
        breakpoint='md'
        onBreakpoint={() => {
          props.handleDrawerOpen(true)
        }}
        className='app-layout__hide-below-md'
      >
        <LeftMenuTitle {...props} />
        <LeftMenuContent {...props} />
      </Sider>
      <Layout className='app-layout__full-content'>
        <Header className='app-layout__top-header'>
          {React.createElement(props.leftMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'app-layout__menu-toggle app-layout__hide-below-md',
            onClick: props.handleOnCollapse
          })}
          {React.createElement(!props.leftMenuDrawerOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'app-layout__menu-toggle app-layout__show-above-md',
            onClick: props.handleDrawerOpen
          })}
          <Menu className='app-layout__right-menu' mode='horizontal'>
            <Menu.SubMenu title={<Avatar icon={<UserOutlined />} />}>
              <Menu selectable={false}>
                <Menu.Item>
                  <Link to={appRoutes.logout.path}>
                    <LogoutOutlined />
                    {t(layouts.logOut)}
                  </Link>
                </Menu.Item>
              </Menu>
            </Menu.SubMenu>
          </Menu>
        </Header>
        <Content>
          <div className='app-layout__background'>
            {props.authenticationInProgress ? (
              <Spin delay={500} tip={t(layouts.authenticationInProgress)}>
                {props.children}
              </Spin>
            ) : (
              props.children
            )}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>BYŚ ©2020 Created by WorkWy</Footer>
      </Layout>
    </Layout>
  )
}

export default AppLayoutView

import React from 'react'
import { Tag, Spin } from 'antd'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'

type JobSelectorProps = {
  jobs: Job[]
  selectedJobIds: string[]
  setSelectedJobIds: (jobIds: string[]) => void
}

export const JobSelector = ({
  jobs,
  selectedJobIds,
  setSelectedJobIds
}: JobSelectorProps): JSX.Element => {
  const getChecked = (jobId: string): boolean => selectedJobIds.includes(jobId)

  const onChange = (jobId: string, checked: boolean): void => {
    if (checked) {
      setSelectedJobIds([...selectedJobIds, jobId])
    } else {
      setSelectedJobIds(selectedJobIds.filter((id) => id !== jobId))
    }
  }

  if (!jobs || jobs.length === 0) {
    return <div></div>
  }

  return (
    <div>
      {jobs?.map((job) => (
        <Tag.CheckableTag
          checked={getChecked(job._id)}
          key={job._id}
          onChange={(checked: boolean): void => onChange(job._id, checked)}
        >
          {job.name}
        </Tag.CheckableTag>
      ))}
    </div>
  )
}

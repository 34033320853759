import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Popconfirm, Row, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './deviceData.less'

import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Device } from '../../../../providers/ClientProvider/client/services/devices/types'

interface DeviceDataViewProps {
  device?: Device
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
}

const DeviceDataView: FunctionComponent<DeviceDataViewProps> = (props: DeviceDataViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  return (
    <div className='edit-device'>
      <ContentLoader loading={!props.device}>
        <Form
          form={props.form}
          name='edit-Device'
          onFinish={props.onFinish}
          initialValues={props.device}
          layout='vertical'
          className='app-layout__form'
        >
          <Row
            gutter={[32, 32]}
            justify='space-between'
            className='app-layout__columns-arrangement'
          >
            <Col xs={24} sm={24} md={10}>
              <Typography.Title level={4}>{t(dictionary.general.device)}</Typography.Title>
              <Form.Item
                name='imei'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label='IMEI'
              >
                <Input placeholder='IMEI' type='number' />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              loading={props.patchLoading}
              className='app-layout__form-button'
            >
              {t(dictionary.general.save)}
            </Button>
            <Popconfirm
              placement='left'
              title={t(devicesPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onDelete}
            >
              <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                {t(dictionary.general.delete)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default DeviceDataView

import { ReactText, useCallback, useEffect, useState, useContext } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { jobShowRoutes } from '../../../../containers/Router/routes'
import { useFindQuery } from '../../../../query'
import useTable from '../../../../components/useTable'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import qs from 'qs'
import Moment from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { LocationReportViewProps } from './LocationReportView'
import { exportToPDF, exportToCSV } from '../../export-helpers'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

export const useLocationReport = (): LocationReportViewProps => {
  const { t, dictionary } = useContext(LocalizationContext)
  const [timePeriod, setTimePeriod] = useState<RangeValue<Moment.Moment>>([null, null])

  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { data: location } = useFindQuery(
    'locations',
    id
      ? {
          _id: id,
          $limit: 1
        }
      : false
  )

  const { query, onTableChange, currentSort, currentPage } = useTable<Job>()
  const { data: jobLocations } = useFindQuery('jobLocations', {
    locationId: id,
    $limit: 100000
  })

  const jobIds = jobLocations?.data.map((jobLocation) => jobLocation.jobId)
  const { data: jobsRes, status: jobsStatus } = useFindQuery(
    'jobs',
    jobIds?.length
      ? {
          ...query,
          countAssignedWorkers: true,
          _id: jobIds
        }
      : false
  )

  console.log({ jobsRes })

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = {
        $regex: value,
        $options: 'ix'
      }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  useEffect(() => {
    query['start'] = timePeriod?.[0] ? { $gte: timePeriod?.[0].toDate() } : undefined
    query['end'] = timePeriod?.[1] ? { $lte: timePeriod?.[1].toDate() } : undefined
    history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
  }, [timePeriod])

  const handleRedirectToJob = useCallback((id: string): void => {
    history.push(
      generatePath(jobShowRoutes.details.path, {
        id: id
      })
    )
  }, [])

  // FIXME: this should be using the translation files
  const exportHeaders = ['Zlecenie', 'Status', 'Pracownicy', 'Czas rozpoczęcia', 'Czas zakończenia']

  console.log({ currentSort })
  const getExportData = useCallback(() => {
    // TODO: this is very ugly
    const sortKey = Object.keys(currentSort)
      .filter((k) => !!currentSort[k])
      .shift()
    const sortedData =
      jobsRes?.data.sort((jobA: Job, jobB: Job) => {
        if (!sortKey) return 0
        const a = (jobA as any)[sortKey as any]
        const b = (jobB as any)[sortKey as any]

        if (currentSort[sortKey] === 'ascend') {
          return a > b ? 1 : -1
        } else {
          return a < b ? 1 : -1
        }
      }) || []

    const isInTimePeriod = (job: Job): boolean => {
      if (!timePeriod) return true
      const [start, end] = timePeriod
      if (!start || !end) return true
      return new Date(job.start) >= start.toDate() && new Date(job.end) <= end.toDate()
    }

    return (
      sortedData
        .filter(isInTimePeriod)
        .map((job) => [
          job.name,
          t(dictionary.jobStatus[job.status]),
          `${job.assignedWorkers || 0}/${
            job.workersToAssign?.reduce((acc, group) => acc + group.numberOfWorkers, 0) || 0
          }`,
          Moment(job.start).format('DD-MM-YYYY HH:mm'),
          Moment(job.end).format('DD-MM-YYYY HH:mm')
        ]) || []
    )
  }, [jobsRes, timePeriod, currentSort])

  const getExportTitle = useCallback(() => {
    const name = location?.data[0]?.name
    const range =
      timePeriod &&
      timePeriod[0] &&
      timePeriod[1] &&
      `${timePeriod[0].format('DD-MM-YYYY')} - ${timePeriod[1].format('DD-MM-YYYY')}`
    const title = [name, range].filter(Boolean).join(' ')
    return title
  }, [location, timePeriod])

  const handleExportToCSV = useCallback((): void => {
    const filename = `Raport ${getExportTitle()}.csv`
    exportToCSV(getExportData(), exportHeaders, filename)
  }, [getExportData, getExportTitle])

  const handleExportToPDF = useCallback((): void => {
    const title = getExportTitle()
    const filename = `Raport ${title}.pdf`
    exportToPDF(getExportData(), exportHeaders, title, filename)
  }, [getExportData])

  return {
    location: location?.data[0],
    handleTimePeriodChange: setTimePeriod,
    jobs: jobsRes?.data.length ? jobsRes?.data : undefined,
    loading: jobsStatus === 'loading',
    total: jobsRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleRedirectToJob,
    handleFilter,
    handleExportToCSV,
    handleExportToPDF
  }
}

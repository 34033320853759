import qs from 'qs'
import useTable from '../../components/useTable'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { useFindQuery } from '../../query'
import { ReactText, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'

const useWorkers = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Worker>()

  const history = useHistory()

  const { data: workersRes, status } = useFindQuery('workers', {
    ...query,
    getGroups: true,
    getDeviceId: true,
    getLatestScore: true
  })

  const { data: workerGroupsRes } = useFindQuery(
    'workerGroups',
    workersRes
      ? {
          workerId: { $in: workersRes.data.map((worker) => worker._id) }
        }
      : false
  )

  const { data: groupsRes } = useFindQuery(
    'groups',
    workerGroupsRes
      ? {
          $limit: 1000,
          _id: { $in: workerGroupsRes?.data.map((workerGroup) => workerGroup.groupId) }
        }
      : false
  )

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(appRoutes.workerShow.routes.details.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewWorker = useCallback((): void => {
    history.push(appRoutes.workerCreate.path)
  }, [])

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  return {
    workers: workersRes?.data,
    loading: status === 'loading',
    total: workersRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewWorker,
    handleFilter,
    groups: groupsRes?.data || []
  }
}

export default useWorkers

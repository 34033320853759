import { useParams } from 'react-router-dom'
import { useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { JobWorker } from '../../../../providers/ClientProvider/client/services/jobWorkers/types'
import { useAsync } from '../../../../utils/useAsync'
import ClientContext, { IClient } from '../../../../providers/ClientProvider/client'
import { shapeType } from '../../../../components/map/geomanShape.enum'
import { GeoData } from '../../../../components/map/mapType'
import { WorkerMapViewProps } from './WorkerMapView'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { ServiceModels } from '../../../../providers/ClientProvider/client/index'
import { Paginated } from '@feathersjs/feathers'

// -----------------------------------------------------------------------------
//                                   Types
// -----------------------------------------------------------------------------

type Position = NonNullable<Worker['positions']>[number]

type ValidRange = [moment.Moment, moment.Moment]
type RangeValue = [moment.Moment | null, moment.Moment | null] | null

// -----------------------------------------------------------------------------
//                                  Helpers
// -----------------------------------------------------------------------------

const isValidRange = (range: RangeValue): range is ValidRange => {
  if (!range) return false
  if (!range[0] || !range[1]) return false
  return true
}

const sortPositions = (positions: Position[]): Position[] =>
  positions.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())

const positionsToCoordinates = (positions: Position[]): [number, number][] =>
  positions.map((p) => [p.lon, p.lat]) // don't ask why it's backwards, ignorance is bliss

const positionsToGeoData = (positions: Position[], text?: string): GeoData => ({
  geometry: {
    type: shapeType.LineString,
    coordinates: positionsToCoordinates(positions)
  },
  type: 'Feature',
  text
})

const getWorkerCurrentPosition = (worker: Worker): GeoData | undefined => {
  if (worker.geoData) {
    // by default, geodata contains the last location and the last 24 hours of positions
    // we want to display only the last location
    const lastLocation = worker.geoData.find((g) => g.geometry.type === shapeType.Point)
    return lastLocation
  }

  return worker.lastLocation
}

// we do not want to have one long line across all positions, but rather have one line for each job
// we should cut the line at the start and end time of each job
// this function is currently very inefficient, but we can optimize it later
const splitPositionsByJob = (
  positions: Position[],
  jobWorkers: JobWorker[]
): Map<string, Position[]> => {
  const isPositionInJob = (position: Position, jobWorker: JobWorker): boolean => {
    const time = new Date(position.time).getTime()
    const start = new Date(jobWorker.start).getTime()
    const end = new Date(jobWorker.end).getTime()
    return time >= start && time <= end
  }

  const positionsByJobId = new Map<string, Position[]>()

  for (const jw of jobWorkers) {
    positionsByJobId.set(
      jw.jobId,
      positions.filter((p) => isPositionInJob(p, jw))
    )
  }

  return positionsByJobId
}

const keepUnique = <T>(arr: T[]): T[] => Array.from(new Set(arr))

const findAllPaginated = async <T extends keyof ServiceModels>(
  client: IClient,
  service: T,
  query: any
): Promise<ServiceModels[T][]> => {
  const res = (await client.service<T>(service).find({ query })) as Paginated<ServiceModels[T]>
  const data = res.data

  if (res.total > data.length) {
    const nextData = await findAllPaginated(client, service, {
      ...query,
      $skip: data.length
    })
    return [...data, ...nextData]
  }

  return data
}

// -----------------------------------------------------------------------------
//                                  Fetching
// -----------------------------------------------------------------------------

const fetchWorker = async (client: IClient, workerId: string): Promise<Worker | undefined> =>
  client
    .service('workers')
    .find({
      query: {
        _id: workerId,
        $limit: 1,
        getFullExternalData: true,
        getDeviceId: true
      }
    })
    .then((res) => res.data[0])

const fetchJobWorkers = async (
  client: IClient,
  workerId: string,
  range: ValidRange
): Promise<JobWorker[]> =>
  findAllPaginated(client, 'jobWorkers', {
    workerId: workerId,
    $limit: 1000,
    start: {
      $lte: range[1].toISOString()
    },
    end: {
      $gte: range[0].toISOString()
    }
  })

const fetchPositions = async (
  client: IClient,
  workerId: string,
  range: ValidRange
): Promise<Position[]> =>
  client
    .service('workers')
    .find({
      query: {
        _id: workerId,
        $limit: 1,
        getPositions: true,
        startDate: range[0].toISOString(),
        endDate: range[1].toISOString()
      }
    })
    .then((res) => res.data[0]?.positions || [])

const fetchDeviceLocation = async (
  client: IClient,
  deviceId: string
): Promise<GeoData | undefined> =>
  client
    .service('devices')
    .find({
      query: {
        _id: deviceId,
        $limit: 1,
        getFullExternalData: true
      }
    })
    .then((res) => res.data[0])
    .then((device) => device?.lastLocation)

// -----------------------------------------------------------------------------
//                                Custom hooks
// -----------------------------------------------------------------------------

type UseWorkerResult = { worker: Worker | undefined; loading: boolean }

const useWorker = (): UseWorkerResult => {
  const { id } = useParams<{ id: string }>()
  const client = useContext(ClientContext)
  const fetchFn = useCallback(() => fetchWorker(client, id), [id])
  const { data: worker, loading } = useAsync(fetchFn, undefined)
  return { worker, loading }
}

type UseJobWorkersResult = { jobWorkers: JobWorker[]; loading: boolean }

const useJobWorkers = (
  workerId: string | undefined,
  rangeValue: RangeValue
): UseJobWorkersResult => {
  const client = useContext(ClientContext)

  const tryFetchJobWorkers = useCallback(async (): Promise<JobWorker[]> => {
    if (!workerId) return []
    if (!isValidRange(rangeValue)) return []
    return fetchJobWorkers(client, workerId, rangeValue)
  }, [workerId, rangeValue])

  const { data: jobWorkers, loading } = useAsync<JobWorker[], JobWorker[]>(tryFetchJobWorkers, [])

  return { jobWorkers, loading }
}

type UsePositionsResult = { positions: Position[]; loading: boolean }

const usePositions = (workerId: string | undefined, range: RangeValue): UsePositionsResult => {
  const client = useContext(ClientContext)

  const tryFetchPositions = useCallback(async (): Promise<Position[]> => {
    if (!workerId) return []
    if (!isValidRange(range)) return []
    return fetchPositions(client, workerId, range)
  }, [workerId, range])

  const { data: positions, loading } = useAsync<Position[], Position[]>(tryFetchPositions, [])

  return { positions, loading }
}

type UseJobsResult = { jobs: Job[]; loading: boolean }

const useJobs = (jobIds: string[]): UseJobsResult => {
  const client = useContext(ClientContext)
  const query = useMemo(() => ({ _id: { $in: jobIds } }), [jobIds])
  const fetchFn = useCallback(async (): Promise<Job[]> => {
    if (!jobIds || jobIds.length === 0) return []
    const res = await findAllPaginated(client, 'jobs', { query })
    return res
  }, [query])
  const { data: jobs, loading } = useAsync(fetchFn, [])
  return { jobs, loading }
}

const useDeviceLocation = (
  deviceId?: string
): { deviceLocation: GeoData | undefined; loading: boolean } => {
  const client = useContext(ClientContext)
  const fetchFn = useCallback(async () => {
    if (!deviceId) return undefined
    return fetchDeviceLocation(client, deviceId)
  }, [deviceId])
  const { data: deviceLocation, loading } = useAsync(fetchFn, undefined)
  return { deviceLocation, loading }
}

// -----------------------------------------------------------------------------
//                                useWorkerMap
// -----------------------------------------------------------------------------

const useWorkerMap = (): WorkerMapViewProps => {
  const [rangeValue, setRangeValue] = useState<RangeValue>(null)
  const { worker, loading: workerLoading } = useWorker()
  const { jobWorkers: allJobWorkers, loading: jobWorkersLoading } = useJobWorkers(
    worker?._id,
    rangeValue
  )

  const jobIds = useMemo(
    () => keepUnique(allJobWorkers?.map((jw) => jw.jobId) || []),
    [allJobWorkers]
  )
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([])
  useEffect(() => {
    setSelectedJobIds(jobIds)
  }, [jobIds])
  const { jobs, loading: jobsLoading } = useJobs(jobIds)
  const jobNameMap = useMemo(() => new Map(jobs.map((job) => [job._id, job.name])), [jobs])

  const jobWorkers = useMemo(
    () => allJobWorkers.filter((jw) => selectedJobIds.includes(jw.jobId)),
    [allJobWorkers, selectedJobIds]
  )

  const { positions, loading: positionsLoading } = usePositions(worker?._id, rangeValue)
  const sortedPositions = useMemo(() => sortPositions(positions), [positions])
  const positionsByJob = useMemo(
    () => splitPositionsByJob(sortedPositions, jobWorkers),
    [sortedPositions, jobWorkers]
  )

  const positionsGeoData = useMemo(() => {
    const result: GeoData[] = []

    for (const [jobId, positions] of positionsByJob.entries()) {
      const jobName = jobNameMap.get(jobId)
      if (!jobName) continue
      const geoData = positionsToGeoData(positions, jobName)
      result.push(geoData)
    }

    return result
  }, [positionsByJob])

  const { deviceLocation, loading: deviceLocationLoading } = useDeviceLocation(worker?.deviceId)

  const currentPosition = useMemo(() => {
    if (deviceLocation) return [deviceLocation]
    if (!worker) return []
    const pos = getWorkerCurrentPosition(worker)
    if (!pos) return []
    return [pos]
  }, [worker, deviceLocation])
  const geoData = useMemo(
    () => [...currentPosition, ...positionsGeoData],
    [currentPosition, positionsGeoData]
  )

  const loading =
    workerLoading || jobWorkersLoading || positionsLoading || jobsLoading || deviceLocationLoading

  return {
    worker,
    geoData,
    rangeValue,
    setRangeValue,
    loading,
    jobs,
    selectedJobIds,
    setSelectedJobIds
  }
}

export default useWorkerMap

import * as d3 from 'd3'
import { JobRange, Score } from './types'
import React, { useMemo, useCallback } from 'react'
import XAxis from './XAxis'
import YAxis from './YAxis'
import Line from './Line'
import Jobs from './Jobs'
import MainBrush from './MainBrush'

type MainGraphProps = {
  width: number
  height: number
  scores: Score[]
  jobs: JobRange[]
  domain: [Date, Date]
  zoom: [Date, Date]
  setZoom: (zoom: [Date, Date]) => void
}

const margins = {
  left: 40,
  right: 20,
  top: 20,
  bottom: 20
}

const MainGraph = (props: MainGraphProps): JSX.Element => {
  const { zoom, scores, setZoom, domain } = props
  const width = props.width - margins.left - margins.right
  const height = props.height - margins.top - margins.bottom
  const xScale = useMemo(() => d3.scaleTime().domain(zoom).range([0, width]), [width, zoom])
  const yScale = useMemo(
    () => d3.scaleLinear().domain([0, 100]).range([height, 0]),
    [height, scores]
  )
  const onDoubleClick = useCallback(() => setZoom(domain), [domain, setZoom])

  // show highlights and tooltips
  const onMouseOver = useCallback(() => {
    d3.select('#highlights-and-tooltips').style('opacity', 1)
  }, [])

  // update highlights and tooltips
  const onMouseMove = useCallback(
    (e) => {
      const mousePosInSVG = d3.pointer(e)
      const mousePosInGraph = [mousePosInSVG[0] - margins.left, mousePosInSVG[1] - margins.top]
      const [x] = mousePosInGraph

      const getHighlightedScore = (): Score | undefined => {
        const bisect = d3.bisector<Score, Date>((d) => d.date).center
        const i = bisect(scores, xScale.invert(x), 1)
        const d = scores[i]
        if (!d) return undefined
        const isCloseToScore = Math.abs(xScale(d.date) - x) < 20
        if (!isCloseToScore) return undefined
        if (isNaN(d.value)) return undefined
        return d
      }

      const score = getHighlightedScore()

      if (score) {
        // move the highlight circle
        d3.select('#highlight-circle')
          .attr('display', 'block')
          .attr('cx', xScale(score.date))
          .attr('cy', yScale(score.value))
        // update the tooltip
        d3.select('#score-tooltip').style('display', 'block')
        d3.select('#activity-span').text(`Aktywnosc: ${score.value.toFixed(2)}`)
        d3.select('#time-span').text(`Czas: ${score.date.toLocaleString()}`)
      } else {
        // hide the highlight circle
        d3.select('#highlight-circle').attr('display', 'none')
        // hide the tooltip
        d3.select('#score-tooltip').style('display', 'none')
      }

      // find the job under the cursor
      const job = props.jobs.find((j) => x >= xScale(j.start) && x <= xScale(j.end))

      if (job) {
        // show the job tooltip
        const group = d3.select('#job-tooltip').style('display', 'block')
        // update the job tooltip
        group.select('#job-name').text(job.name)
        group.select('#job-start').text(`Od: ${job.start.toLocaleString()}`)
        group.select('#job-end').text(`Do: ${job.end.toLocaleString()}`)
      } else {
        // hide the job tooltip
        d3.select('#job-tooltip').style('display', 'none')
      }
    },
    [xScale, yScale, scores]
  )

  // hide highlights and tooltips
  const onMouseOut = useCallback(() => {
    d3.select('#highlights-and-tooltips').style('opacity', 0)
  }, [])

  return (
    <g
      width={props.width}
      height={props.height}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
    >
      <g
        className='main'
        width={width}
        height={height}
        transform={`translate(${margins.left}, ${margins.top})`}
      >
        <defs>
          <clipPath id='clip'>
            <rect width={width} height={height} />
          </clipPath>
        </defs>
        <g transform={`translate(0, ${height})`}>
          <XAxis xScale={xScale} zoom={zoom} height={height} />
        </g>
        <YAxis yScale={yScale} width={width} />
        <g height='100%' clipPath='url(#clip)'>
          <MainBrush
            width={width}
            height={height}
            domain={props.domain}
            xScale={xScale}
            zoom={zoom}
            setZoom={setZoom}
          />
          <Jobs jobs={props.jobs} xScale={xScale} yScale={yScale} zoom={zoom} />
          <Line xScale={xScale} yScale={yScale} scores={scores} zoom={zoom} />
        </g>
        <g id='highlights-and-tooltips'>
          <g id='score-tooltip' transform={`translate(${width - 200}, 0)`}>
            <rect
              width={220}
              height={50}
              fill='white'
              stroke='steelblue'
              strokeWidth='2px'
              rx='5px'
              ry='5px'
            />
            <text textAnchor='middle' transform='translate(110, 5)'>
              <tspan x='0' dy='1.2em' id='time-span'>
                Czas: 2024-01-01 12:00
              </tspan>
              <tspan x='0' dy='1.2em' id='activity-span'>
                Aktywnosc: 33.33
              </tspan>
            </text>
          </g>
          <g id='job-tooltip' transform={`translate(${width - 200}, 60)`}>
            <rect
              width={220}
              height={70}
              fill='white'
              stroke='lightcoral'
              strokeWidth='2px'
              rx='5px'
              ry='5px'
            />
            <text textAnchor='middle' transform='translate(110, 5)'>
              <tspan x='0' dy='1.2em' id='job-name'>
                Job: 2024-01-01 12:00
              </tspan>
              <tspan x='0' dy='1.2em' id='job-start'>
                Start: 33.33
              </tspan>
              <tspan x='0' dy='1.2em' id='job-end'>
                End: 33.33
              </tspan>
            </text>
          </g>
          <circle cx='0' cy='0' r='5' fill='steelblue' id='highlight-circle' />
        </g>
      </g>
    </g>
  )
}

export default MainGraph

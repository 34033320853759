/* eslint-disable react/display-name */
import React, { FunctionComponent, HTMLAttributes, ReactText, useContext } from 'react'
import { Table } from 'antd'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined, ThunderboltOutlined, WifiOutlined } from '@ant-design/icons'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import DeviceWorker from '../../components/deviceWorker'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import FilterTextSearch from '../../components/FilterTextSearch'
import FilterTags from '../../components/filterTags'
import moment from 'moment'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  devicesPage: any
  t: any
}

export const Columns = (props: ColumnProps): ColumnType<Device>[] => {
  return [
    {
      title: 'IMEI',
      dataIndex: 'imei',
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'imei'
        })
    },
    {
      title: props.t(props.devicesPage.assignedWorker),
      render: (value: string, record: Device): JSX.Element | undefined => (
        <DeviceWorker workerId={record.workerId} />
      )
    },
    {
      title: 'GSM',
      dataIndex: 'gsm',
      sorter: (a: Device, b: Device): number => {
        if (!a.gsm) return -1
        if (!b.gsm) return 1
        return a.gsm - b.gsm
      },
      responsive: ['md'],
      render: (value: number): JSX.Element => {
        return value !== undefined ? (
          <>
            <WifiOutlined />
            {`  ${value} %`}
          </>
        ) : (
          <>N/A</>
        )
      }
    },
    {
      title: 'GPS',
      dataIndex: 'gps',
      sorter: false,
      responsive: ['md']
    },
    {
      title: props.t(props.devicesPage.battery),
      dataIndex: 'battery',
      sorter: (a: Device, b: Device): number => {
        if (!a.battery) return -1
        if (!b.battery) return 1
        return a.battery - b.battery
      },
      responsive: ['md'],
      render: (value: number) => {
        return value !== undefined ? (
          <>
            <ThunderboltOutlined />
            {`  ${value} %`}
          </>
        ) : (
          <>N/A</>
        )
      }
    },
    {
      title: props.t(props.devicesPage.lastUpdate),
      dataIndex: 'lastUpdate',
      defaultSortOrder: 'descend',
      sorter: (a, b): number => {
        if (!a.lastUpdate) return -1
        if (!b.lastUpdate) return 1
        return moment(a.lastUpdate).unix() - moment(b.lastUpdate).unix()
      },
      // responsive: ['md'],
      render: (value: Date) => {
        return value ? <>{moment(value).format('HH:mm D-M-YYYY')}</> : <>N/A</>
      }
    }
  ]
}

interface DevicesViewProps {
  devices?: Device[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Device>
  handleOnRowClick: (id: string) => void
  onCreateNewDevice: () => void
  handleFilter: (value: ReactText, dataIndex: string) => void
  onClearFilter: () => void
}

const DevicesView: FunctionComponent<DevicesViewProps> = (props: DevicesViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  return (
    <ListLayout
      title={t(dictionary.layouts.devices)}
      buttonAction={props.onCreateNewDevice}
      buttonText={t(devicesPage.addDevice)}
    >
      <FilterTags onClear={props.onClearFilter} />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          devicesPage: devicesPage
        })}
        loading={props.loading}
        dataSource={props.devices}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default DevicesView

import React from 'react'
import { DatePicker, Collapse, Button } from 'antd'
import moment from 'moment'

export type RangeValue = [moment.Moment | null, moment.Moment | null] | null

type TimerangeSelectorBarProps = {
  range: RangeValue
  setRange: (range: RangeValue) => void
  title?: string
}

export const TimerangeSelectorBar = ({
  range,
  setRange,
  title
}: TimerangeSelectorBarProps): JSX.Element => {
  const lastNDays = (n: number): RangeValue => [moment().subtract(n, 'day'), moment()]
  const setLast24h = (): void => setRange(lastNDays(1))
  const setLast7d = (): void => setRange(lastNDays(7))
  const setLast30d = (): void => setRange(lastNDays(30))
  const setNoRange = (): void => setRange(null)

  return (
    <Collapse>
      <Collapse.Panel header={title} key='1'>
        <DatePicker.RangePicker showTime value={range} onChange={setRange} />
        <Button onClick={setLast24h}>Ostatnie 24h</Button>
        <Button onClick={setLast7d}>Ostatnie 7 dni</Button>
        <Button onClick={setLast30d}>Ostatnie 30 dni</Button>
        <Button onClick={setNoRange}>Nie pokazuj</Button>
      </Collapse.Panel>
    </Collapse>
  )
}

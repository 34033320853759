import { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetQuery, usePatchMutation } from '../../../../query'
import {
  coordinates,
  GeoData,
  GeomanTarget,
  GeomanTargets,
  LatLng
} from '../../../../components/map/mapType'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import message from 'antd/lib/message'
import { shapeType } from '../../../../components/map/geomanShape.enum'

const useLocationMap = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const locationsPage = dictionary.locationsPage

  const { id } = useParams<{ id: string }>()

  const { data: location } = useGetQuery('locations', id)

  const [map, setMap] = useState<any>()

  const [patchLocation, { status: patchStatus }] = usePatchMutation('locations')

  const onSave = useCallback(async () => {
    if (!id || !map) {
      throw new Error(t(locationsPage.incorrectId))
    }

    if (!map._targets) return

    const geoData: GeoData[] = []

    Object.values(map._targets as GeomanTargets).map((target: GeomanTarget) => {
      if (target._latlngs && target._latlngs[0]) {
        switch (target.pm._shape) {
          case shapeType.Polygon:
          case shapeType.Rectangle:
            geoData.push({
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: (target._latlngs as LatLng[][]).map((latLngs) => {
                  return (latLngs as LatLng[]).map((latLng) => [
                    latLng.lng,
                    latLng.lat
                  ]) as coordinates[]
                })
              }
            })
            break
          case shapeType.Line:
            // TODO: i have no idea what this is
            //       but after commenting it out things seem to work
            //
            // if (target.pm._layerGroup) break // don't save direction arrows from lines

            geoData.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: (target._latlngs as LatLng[]).map((latLng) => [
                  latLng.lng,
                  latLng.lat
                ]) as coordinates[]
              }
            })
            break
        }
      }
    })

    try {
      await patchLocation({
        id,
        data: {
          ...location,
          geoData: geoData
        }
      })
      message.success(t(locationsPage.updated))
    } catch (e) {
      console.log(e)
    }
  }, [map, location])

  return {
    locationGeoJson: location?.geoData,
    setMap,
    onSave,
    patchLoading: patchStatus === 'loading',
    location
  }
}

export default useLocationMap

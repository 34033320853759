import { Form, message } from 'antd'
import moment from 'moment'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { Calendar } from '../../../../providers/ClientProvider/client/services/tasks/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useDeleteMutation, useFindQuery, useGetQuery, usePatchMutation } from '../../../../query'

const useTaskDetails = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const { data: task } = useGetQuery('tasks', id)

  const [groupSearch, setGroupSearch] = useState<string>('')

  const { data: groupsRes } = useFindQuery('groups', {
    $limit: 10,
    name: { $regex: groupSearch, $options: 'i' }
  })

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useMemo(() => {
    if (!task || !task.calendar) return

    setStartDate(task.calendar.startDate)
    setEndDate(task.calendar.endDate)

    if (!task.calendar.range || !task.calendar.range.length) return

    const ranges = task.calendar.range.map((range) => ({
      ...range,
      startTime: moment().startOf('day').add(range.startTime, 'minutes'),
      endTime: moment().startOf('day').add(range.endTime, 'minutes')
    }))

    form.setFieldsValue({
      calendar: {
        range: ranges
      }
    })
  }, [task])

  const [patchTask, { status: patchStatus }] = usePatchMutation('tasks')

  const [deleteTask, { status: deleteStatus }] = useDeleteMutation('tasks')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(tasksPage.incorrectId))
      }

      // validate workersToAssign
      if (values.workersToAssign && values.workersToAssign.length) {
        const groupIds: string[] = []

        for (let i = 0; i < values.workersToAssign.length; i++) {
          if (!values.workersToAssign[i].groupId || !values.workersToAssign[i].numberOfWorkers) {
            message.error(t(tasksPage.missingGroupValueError))
            return
          }

          if (groupIds.indexOf(values.workersToAssign[i].groupId) !== -1) {
            message.error(t(tasksPage.addGroupError))
            return
          }

          groupIds.push(values.workersToAssign[i].groupId)
        }
      }

      const startDateMoment = moment(startDate)
      const startDateTrimmed = new Date(
        Date.UTC(startDateMoment.year(), startDateMoment.month(), startDateMoment.date())
      )

      const endDateMoment = moment(endDate)
      const endDateTrimmed = new Date(
        Date.UTC(endDateMoment.year(), endDateMoment.month(), endDateMoment.date(), 23, 59, 59)
      )

      const calendar: Calendar = {
        startDate: startDateTrimmed,
        endDate: endDateTrimmed,
        range: []
      }

      // validate calendar
      if (values.calendar) {
        if (values.calendar.range && values.calendar.range.length) {
          const ranges = values.calendar.range

          for (let i = 0; i < ranges.length; i++) {
            if (
              ranges[i].endDay === undefined ||
              !ranges[i].endTime ||
              ranges[i].startDay === undefined ||
              !ranges[i].startTime ||
              !startDate ||
              !endDate
            ) {
              message.error(t(dictionary.calendar.missingFieldError))
              return
            }

            // get local timezone
            const timezoneOffset = new Date().getTimezoneOffset() / 60

            const startDuration = moment.duration(ranges[i].startTime)
            const startMinutes = startDuration.minutes()
            const startHours = (startDuration.hours() - timezoneOffset + 24) % 24

            const startTime = startMinutes + startHours * 60

            const endDuration = moment.duration(ranges[i].endTime)
            const endMinutes = endDuration.minutes()
            const endHours = (endDuration.hours() - timezoneOffset + 24) % 24

            const endTime = endMinutes + endHours * 60

            if (ranges[i].startDay === ranges[i].endDay && endTime <= startTime) {
              message.error(t(dictionary.calendar.errorMessage))
              return
            }

            calendar.range.push({
              startDay: ranges[i].startDay,
              startTime: startTime,
              endDay: ranges[i].endDay,
              endTime: endTime
            })
          }
        }
      }

      values.calendar = calendar

      try {
        await patchTask({
          id,
          data: values
        })

        message.success(t(tasksPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id, startDate, endDate]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(tasksPage.incorrectId))
    }

    try {
      await deleteTask(id)

      history.push(appRoutes.tasks.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    task,
    workers: workersRes?.data || [],
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading',
    setGroupSearch,
    setWorkerSearch,
    groups: groupsRes?.data,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  }
}

export default useTaskDetails

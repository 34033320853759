import React, { FunctionComponent, ReactText, useContext, useEffect, useCallback } from 'react'
import '../../Roboto-Regular-normal'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Table, Typography, DatePicker, Spin } from 'antd'
import FilterTags from '../../../../components/filterTags'
import { SortOrder } from 'antd/lib/table/interface'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { onTableChange } from '../../../../components/useTable'
import { RangeValue } from 'rc-picker/lib/interface'
import Moment from 'moment' //type
import { getReportData, ReportDataRow } from './report-builder'

import '../../reports.less'
import Button from 'antd/es/button'
import ClientContext from '../../../../providers/ClientProvider/client'
import { exportToCSV, exportToPDF } from '../../export-helpers'
import { useAsync } from '../../../../utils/useAsync'

export interface WorkerReportViewProps {
  workerId: string
  worker?: Worker
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Job>
  handleFilter: (value: ReactText, dataIndex: string) => void
  handleTimePeriodChange: (range: RangeValue<Moment.Moment>) => void
}

const WorkerReportView: FunctionComponent<WorkerReportViewProps> = (
  props: WorkerReportViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)

  const reportsPage = dictionary.reportsPage

  const client = useContext(ClientContext)

  const reportDataFetchFn = useCallback(() => getReportData(client, props.workerId), [
    props.workerId
  ])
  const { data: rawData, loading } = useAsync(reportDataFetchFn, [])

  const [sortedData, setSortedData] = React.useState<ReportDataRow[]>([])

  useEffect(() => {
    if (!props.workerId) return
    if (!rawData) return
    setSortedData(rawData)
  }, [rawData])

  const [timePeriod, setTimePeriod] = React.useState<RangeValue<Moment.Moment>>(null)

  const isInTimePeriod = useCallback(
    (row: ReportDataRow): boolean => {
      if (!timePeriod) return true
      const [start, end] = timePeriod
      if (!start || !end) return true
      return row.start >= start.toDate() && row.end <= end.toDate()
    },
    [timePeriod]
  )

  const data = rawData.filter(isInTimePeriod)

  const exportableHeaders = ['Nazwa', 'Status', 'Pracownicy', 'Start', 'Koniec', 'Ocena']

  const getExportableRows = useCallback(() => {
    // we need to filter because the table onChange doesn't trigger when new data is passed
    return sortedData
      .filter(isInTimePeriod)
      .map((j) => [
        j.name,
        t((dictionary.jobStatus as any)[j.status]),
        `${j.assignedWorkers}/${j.requiredWorkers}`,
        new Date(j.start).toLocaleString('pl-PL'),
        new Date(j.end).toLocaleString('pl-PL'),
        j.score.toFixed(2)
      ])
  }, [sortedData, t, isInTimePeriod])

  const getReportTitle = useCallback(() => {
    const name = `${props.worker?.firstName} ${props.worker?.lastName}`
    const reportRange =
      timePeriod &&
      timePeriod[0] &&
      timePeriod[1] &&
      timePeriod[0].format('DD-MM-YYYY') + ' - ' + timePeriod[1].format('DD-MM-YYYY')
    const title = `${name} ${reportRange || ''}`
    return title
  }, [props.worker, timePeriod])

  const handleExportToCSV = useCallback(() => {
    const filename = `Raport ${getReportTitle()}.csv`
    exportToCSV(getExportableRows(), exportableHeaders, filename)
  }, [getExportableRows, getReportTitle])

  const handleExportToPDF = useCallback(() => {
    const title = getReportTitle()
    const filename = `Raport ${title}.pdf`
    exportToPDF(getExportableRows(), exportableHeaders, title, filename)
  }, [getExportableRows, getReportTitle])

  return (
    <>
      <Typography.Title level={3} className='reports--title'>
        {props.worker?.firstName} {props.worker?.lastName}
      </Typography.Title>
      <div className='reports--date-picker'>
        {t(reportsPage.reportForPeriod)}
        <DatePicker.RangePicker
          allowEmpty={[true, true]} //start, end
          onCalendarChange={(values: RangeValue<Moment.Moment>): void => {
            setTimePeriod(values)
          }}
        />
      </div>
      <FilterTags skippedKeys={['start', 'end']} />
      {loading ? (
        <Spin />
      ) : (
        <Table
          columns={[
            {
              title: t(dictionary.reportsPage.jobName),
              dataIndex: 'name',
              render: function workerName(value: string, record: ReportDataRow): JSX.Element {
                return <a href={`/app/jobs/${record.jobId}/details`}>{value}</a>
              }
            },
            {
              title: t(dictionary.jobsPage.status),
              dataIndex: 'status',
              render: (value: string): string => t((dictionary.jobStatus as any)[value])
            },
            {
              title: t(dictionary.jobsPage.workers),
              render: (_: number, record: ReportDataRow): string =>
                `${record.assignedWorkers}/${record.requiredWorkers}`
            },
            {
              title: t(dictionary.jobsPage.startDate),
              dataIndex: 'start',
              render: (value: Date): string => Moment(value).format('HH:mm DD-MM-YYYY'),
              showSorterTooltip: true,
              defaultSortOrder: 'descend',
              sorter: (a: ReportDataRow, b: ReportDataRow): number =>
                a.start.getTime() - b.start.getTime()
            },
            {
              title: t(dictionary.jobsPage.endDate),
              dataIndex: 'end',
              render: (value: Date): string => Moment(value).format('HH:mm DD-MM-YYYY'),
              showSorterTooltip: true,
              sorter: (a: ReportDataRow, b: ReportDataRow): number =>
                a.start.getTime() - b.start.getTime()
            },
            {
              title: t(dictionary.workersPage.score),
              dataIndex: 'score',
              showSorterTooltip: true,
              sorter: (a: ReportDataRow, b: ReportDataRow): number => a.score - b.score,
              render: (value: number): string => value.toFixed(2)
            }
          ]}
          rowKey={(record: ReportDataRow): string => record.jobId}
          dataSource={data}
          onChange={(_0, _1, _2, extra): void => {
            setSortedData(extra.currentDataSource)
          }}
        />
      )}
      {/* <FilterTags skippedKeys={['start', 'end']} /> */}
      <div>
        <Button onClick={handleExportToCSV}>Pobierz CSV</Button>
        <Button onClick={handleExportToPDF}>Pobierz PDF</Button>
      </div>
    </>
  )
}

export default WorkerReportView

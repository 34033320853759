import { ReactText, useCallback, useMemo } from 'react'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useFindQuery } from '../../../../query'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import useTable from '../../../../components/useTable'
import { jobShowRoutes } from '../../../../containers/Router/routes'
import qs from 'qs'
import { WorkerDetailsViewProps } from './WorkerDetailsView'

const notEmpty = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined

const useWorkerDetails = (): WorkerDetailsViewProps => {
  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { query, onTableChange, currentSort, currentPage } = useTable<Job>()

  const { data: worker } = useFindQuery(
    'workers',
    id
      ? {
          _id: id,
          $limit: 1,
          getDeviceId: true,
          getLatestScore: true,
          getFullExternalData: true,
          getStats: true
        }
      : false
  )

  // currently worker positions are only collected during jobs
  // if we want the true latest position we should get it from the device
  const { data: device } = useFindQuery(
    'devices',
    worker?.data[0]?.deviceId
      ? {
          _id: worker?.data[0]?.deviceId,
          $limit: 1,
          getFullExternalData: true
        }
      : false
  )

  const geoData = useMemo(() => {
    const workerLastLocationFromGeoData = worker?.data[0]?.geoData
      ?.filter((g) => g.geometry.type === 'Point')
      .shift()
    const workerLastLocationFromLastLocation = worker?.data[0]?.lastLocation
    const deviceLastLocation = device?.data[0]?.lastLocation
    const lastLocation =
      deviceLastLocation || workerLastLocationFromGeoData || workerLastLocationFromLastLocation
    const workerPathGeoData = worker?.data[0]?.geoData
      ?.filter((g) => g.geometry.type === 'LineString')
      .shift()
    return [lastLocation, workerPathGeoData].filter(notEmpty)
  }, [worker, device])

  const { data: jobWorkers } = useFindQuery('jobWorkers', {
    workerId: id,
    active: true,
    getScore: true,
    $limit: 100000
  })

  const scoresByJobId = useMemo(
    () => new Map(jobWorkers?.data.map((jw) => [jw.jobId, jw.score]) || []),
    [jobWorkers]
  )
  console.log(scoresByJobId)

  const jobIds = useMemo(() => {
    if (!jobWorkers) return []

    return jobWorkers?.data.map((job) => job.jobId)
  }, [jobWorkers])

  const { data: jobsRes, status: jobsStatus } = useFindQuery(
    'jobs',
    jobIds?.length ? { ...query, _id: jobIds, $sort: { start: '-1' } } : false
  )

  const jobsWithScores = useMemo(() => {
    if (!jobsRes || !jobWorkers) return []
    return jobsRes.data.map((job) => {
      const score = scoresByJobId.get(job._id)
      return { ...job, score }
    })
  }, [jobsRes, scoresByJobId])

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(jobShowRoutes.details.path, {
        id: id
      })
    )
  }, [])

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  const countRequiredWorkers = useCallback((job: Job): number => {
    let allRequiredWorkers = 0

    job.workersToAssign?.map((group) => {
      allRequiredWorkers += group.numberOfWorkers
    })
    return allRequiredWorkers
  }, [])

  return {
    workerId: id,
    worker: worker?.data[0],
    jobWorkersTotal: jobWorkers?.total || 0,
    jobs: jobsWithScores.length ? jobsWithScores : undefined,
    loading: jobsStatus === 'loading',
    total: jobsRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    handleFilter,
    countRequiredWorkers,
    geoData
  }
}

export default useWorkerDetails

import { Row } from 'antd'
import React, { FunctionComponent, useContext, useMemo } from 'react'
import { Calendar } from '../../providers/ClientProvider/client/services/tasks/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

import './calendarDays.less'

interface CalendarDaysProps {
  calendar?: Calendar
}

const CalendarDays: FunctionComponent<CalendarDaysProps> = (props: CalendarDaysProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const calendarLoc = dictionary.calendar

  const daysOfWeek = useMemo(() => {
    return [
      t(calendarLoc.mondayShort),
      t(calendarLoc.tuesdayShort),
      t(calendarLoc.wednesdayShort),
      t(calendarLoc.thursdayShort),
      t(calendarLoc.fridayShort),
      t(calendarLoc.saturdayShort),
      t(calendarLoc.sundayShort)
    ]
  }, [])

  return (
    <Row className='calendar-days'>
      {Object.entries(daysOfWeek).map((dayOfWeek, index) => {
        const className =
          props.calendar &&
          props.calendar.range &&
          props.calendar.range.filter((taskRange) => taskRange.startDay === index).length
            ? 'calendar-days__day-container calendar-days__day-container-active'
            : 'calendar-days__day-container'

        return (
          <div key={index} className={className}>
            {dayOfWeek[1]}
          </div>
        )
      })}
    </Row>
  )
}

export default CalendarDays

import qs from 'qs'
import useTable from '../../components/useTable'
import { useFindQuery } from '../../query'
import { ReactText, useCallback, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'

const useDevices = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Device>()

  const history = useHistory()

  // TODO: this is a temporary solution, currently correct pagination is not
  //       supported since most of the fields are fetched from external API
  //       so it's impossible to sort or filter by them with the default
  //       feathersjs query params
  const { data: devicesRes, status } = useFindQuery('devices', {
    // ...query,
    getWorkerId: true,
    getBasicExternalData: true,
    $limit: 1000 // FIXME: This is super hacky
  })

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(appRoutes.deviceShow.routes.details.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewDevice = useCallback((): void => {
    history.push(appRoutes.deviceCreate.path)
  }, [])

  const [filter, setFilter] = useState<{ dataIndex: string; value: ReactText }>()

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      setFilter({ dataIndex, value: (value || '').toString() })
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  const onClearFilter = useCallback(() => {
    setFilter(undefined)
  }, [])

  return {
    devices: devicesRes?.data?.filter((device) => {
      if (!filter) {
        return true
      }

      return (device as any)[filter.dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(filter.value?.toString()?.toLowerCase() || '')
    }),
    loading: status === 'loading',
    total: devicesRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewDevice,
    handleFilter,
    onClearFilter
  }
}

export default useDevices

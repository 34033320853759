import React, { useContext, useMemo } from 'react'
import { Button, Row, Col, Form, Select, Typography, TimePicker } from 'antd'

import './calendarInput.less'

import { DayOfWeek } from '../../providers/ClientProvider/client/services/tasks/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'

interface TimePickerValidatedProps {
  form: FormInstance
  rangeIndex: number
  fieldName: string
}

const TimePickerValidated = (props: TimePickerValidatedProps): JSX.Element => {
  const calendar = props.form.getFieldValue('calendar')

  const setField = (value: any): void => {
    if (!calendar.range[props.rangeIndex]) {
      calendar.range[props.rangeIndex] = {}
    }

    calendar.range[props.rangeIndex][props.fieldName] = value

    props.form.setFieldsValue({
      calendar: calendar
    })
  }

  return (
    <TimePicker
      format='HH:mm'
      style={{ width: '100%' }}
      onChange={setField}
      defaultValue={
        calendar.range[props.rangeIndex]
          ? calendar.range[props.rangeIndex][props.fieldName]
          : undefined
      }
    />
  )
}

interface CalendarInputViewProps {}

const CalendarInputView = (props: CalendarInputViewProps): any => {
  const { t, dictionary } = useContext(LocalizationContext)
  const calendarLoc = dictionary.calendar

  const daysOfWeek: {
    day: DayOfWeek
    display: string
  }[] = useMemo(() => {
    return [
      {
        day: 0,
        display: t(calendarLoc.monday)
      },
      {
        day: 1,
        display: t(calendarLoc.tuesday)
      },
      {
        day: 2,
        display: t(calendarLoc.wednesday)
      },
      {
        day: 3,
        display: t(calendarLoc.thursday)
      },
      {
        day: 4,
        display: t(calendarLoc.friday)
      },
      {
        day: 5,
        display: t(calendarLoc.saturday)
      },
      {
        day: 6,
        display: t(calendarLoc.sunday)
      }
    ]
  }, [])

  return (
    <Form.List name={['calendar', 'range']}>
      {(fields, { add, remove }): JSX.Element => {
        return (
          <div className='calendar-input'>
            {fields.map((field, index) => (
              <Row key={`range_${index}`} className='calendar-input__range-row'>
                <Col span={22}>
                  <Row gutter={[8, 8]} className='calendar-input__day-time-row'>
                    <Col span={4}>
                      <Typography.Text>{t(calendarLoc.start)}</Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...field}
                        key={`${field.key}_start_day`}
                        name={[field.name, 'startDay']}
                        fieldKey={[field.fieldKey, 'startDay']}
                        required={true}
                      >
                        <Select
                          showArrow
                          placeholder={t(dictionary.general.chooseFromList)}
                          className='calendar-input__day-select'
                        >
                          {daysOfWeek.map((day) => {
                            return (
                              <Select.Option key={day.day} value={day.day}>
                                {day.display}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        key={`${field.key}_start_time`}
                        fieldKey={[field.fieldKey, 'startTime']}
                        required={true}
                        shouldUpdate
                      >
                        {(form: FormInstance): JSX.Element =>
                          TimePickerValidated({
                            form: form,
                            rangeIndex: field.name,
                            fieldName: 'startTime'
                          })
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]} className='calendar-input__day-time-row'>
                    <Col span={4}>
                      <Typography.Text>{t(calendarLoc.end)}</Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...field}
                        key={`${field.key}_end_day`}
                        name={[field.name, 'endDay']}
                        fieldKey={[field.fieldKey, 'endDay']}
                        required={true}
                      >
                        <Select
                          showArrow
                          placeholder={t(dictionary.general.chooseFromList)}
                          className='calendar-input__day-select'
                        >
                          {daysOfWeek.map((day) => {
                            return (
                              <Select.Option key={day.day} value={day.day}>
                                {day.display}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        key={`${field.key}_end_time`}
                        fieldKey={[field.fieldKey, 'endTime']}
                        required={true}
                        shouldUpdate
                      >
                        {(form: FormInstance): JSX.Element =>
                          TimePickerValidated({
                            form: form,
                            rangeIndex: field.name,
                            fieldName: 'endTime'
                          })
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={2} className='calendar-input__remove-range-column'>
                  <Button
                    shape='circle'
                    icon={<MinusOutlined />}
                    onClick={() => remove(field.name)}
                    size='small'
                  />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => {
                  add()
                }}
                style={{ width: '100%' }}
              >
                <PlusOutlined /> {t(calendarLoc.addRange)}
              </Button>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  )
}

export default CalendarInputView

import React, { FunctionComponent, useContext } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  DatePicker,
  Typography
} from 'antd'
import { FormInstance } from 'antd/lib/form'

import './workerData.less'

import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Group } from '../../../../providers/ClientProvider/client/services/groups/types'
import { Device } from '../../../../providers/ClientProvider/client/services/devices/types'
import moment from 'moment'
import { generatePath, Link } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { timezonesOptions } from '../../timezonesOptions'

interface WorkerDataViewProps {
  worker?: Worker
  device?: Device
  deviceLoading: boolean
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
  deleteWorkerDeviceLoading: boolean
  availableGroups: Group[]
  thisWorkerGroups: Group[]
  thisWorkerGroupsLoading: boolean
  setSelectedGroup: (value: string) => void
  onAddToGroup: () => void
  createWorkerGroupLoading: boolean
  onRemoveGroup: (groupId: string) => void
  onTurnOver: () => void
  onAssignDevice: () => void
  availableDevices: Device[]
  setSelectedDevice: (value: string) => void
  startDate?: Date
  setStartDate: (date: Date) => void
  endDate?: Date
  setEndDate: (date: Date | undefined) => void
  setGroupSearch: (value: string) => void
  setDeviceSearch: (value: string) => void
}

const WorkerDataView: FunctionComponent<WorkerDataViewProps> = (props: WorkerDataViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const workersPage = dictionary.workersPage

  return (
    <div className='edit-worker'>
      <ContentLoader loading={!props.worker}>
        <Form
          form={props.form}
          name='edit-worker'
          onFinish={props.onFinish}
          initialValues={props.worker}
          layout='vertical'
          className='app-layout__form'
        >
          <Row
            gutter={[32, 32]}
            justify='space-between'
            className='app-layout__columns-arrangement'
          >
            <Col xs={24} sm={24} md={10}>
              <Typography.Title level={4}>{t(workersPage.basicData)}</Typography.Title>
              <Form.Item
                name='firstName'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.firstName)}
              >
                <Input placeholder={t(workersPage.firstName)} type='text' />
              </Form.Item>
              <Form.Item
                name='lastName'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.lastName)}
              >
                <Input placeholder={t(workersPage.lastName)} type='text' />
              </Form.Item>
              <Form.Item name='phoneNumber' label={t(workersPage.phoneNumber)}>
                <Input placeholder={t(workersPage.phoneNumber)} type='text' />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={'Email'}
              >
                <Input placeholder={'Email'} type='text' />
              </Form.Item>
              <Form.Item
                name='identifier'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.identifier)}
              >
                <Input placeholder={t(workersPage.identifier)} type='text' />
              </Form.Item>
              <Form.Item name='active' label={t(workersPage.active)} valuePropName='checked'>
                <Switch />
              </Form.Item>
              <Form.Item name='manager' label={t(workersPage.manager)} valuePropName='checked'>
                <Switch />
              </Form.Item>
              <Form.Item
                name='timezone'
                label={t(dictionary.general.timezone)}
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
              >
                <Select showSearch showArrow>
                  {timezonesOptions.map((timezone) => (
                    <Select.Option key={timezone} value={timezone}>
                      {timezone}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Typography.Title level={4}>{t(workersPage.calendar)}</Typography.Title>
              <Form.Item
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.startDate)}
                shouldUpdate
              >
                {(): JSX.Element => {
                  return (
                    <DatePicker
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={moment(props.startDate)}
                      onChange={(momentDate): void =>
                        props.setStartDate(moment(momentDate).toDate())
                      }
                      placeholder={t(dictionary.general.chooseDate)}
                    />
                  )
                }}
              </Form.Item>
              <Form.Item label={t(workersPage.endDate)} shouldUpdate>
                {(): JSX.Element => {
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={props.endDate ? moment(props.endDate) : null}
                      onChange={(momentDate): void =>
                        props.setEndDate(momentDate ? moment(momentDate).toDate() : undefined)
                      }
                      placeholder={t(dictionary.general.chooseDate)}
                    />
                  )
                }}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10}>
              <Typography.Title level={4}>{t(workersPage.assignment)}</Typography.Title>
              <Typography.Title level={5}>{t(workersPage.groups)}</Typography.Title>
              <Row className='edit-worker__tags-row'>
                {props.thisWorkerGroupsLoading ? (
                  <Spin size='small' />
                ) : (
                  props.thisWorkerGroups.map((group) => (
                    <Tag
                      closable
                      onClose={() => props.onRemoveGroup(group._id)}
                      key={group._id}
                      className='edit-worker__tag'
                    >
                      <Link to={generatePath(appRoutes.groupShow.path, { id: group._id })}>
                        {group.name}
                      </Link>
                    </Tag>
                  ))
                )}
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Select
                    className='edit-worker__select'
                    showSearch
                    showArrow
                    placeholder={t(dictionary.general.chooseFromList)}
                    filterOption={false}
                    onSelect={(value) => props.setSelectedGroup(value.toString())}
                    onSearch={props.setGroupSearch}
                  >
                    {props.availableGroups.map((group: Group) => {
                      return (
                        <Select.Option key={group._id} value={group._id}>
                          {group.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Button
                    onClick={props.onAddToGroup}
                    loading={props.createWorkerGroupLoading || props.thisWorkerGroupsLoading}
                  >
                    {t(dictionary.groupsPage.addToGroup)}
                  </Button>
                </Col>
              </Row>
              <br />
              <Typography.Title level={5}>{t(dictionary.general.device)}</Typography.Title>
              {props.deviceLoading ? (
                <Spin size='small' />
              ) : props.device ? (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Link
                      to={generatePath(appRoutes.deviceShow.routes.details.path, {
                        id: props.device?._id
                      })}
                      onClick={(event): void => event.stopPropagation()}
                    >
                      {props.device.imei}
                    </Link>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Button onClick={props.onTurnOver} loading={props.deleteWorkerDeviceLoading}>
                      {t(dictionary.general.turnOver)}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Select
                      className='edit-worker__select'
                      showSearch
                      showArrow
                      placeholder={'IMEI'}
                      filterOption={false}
                      onSelect={(value) => props.setSelectedDevice(value.toString())}
                      onSearch={props.setDeviceSearch}
                    >
                      {props.availableDevices?.map((device: Device) => (
                        <Select.Option key={device._id} value={device._id}>
                          {device.imei}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Button onClick={props.onAssignDevice}>{t(dictionary.general.assign)}</Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              loading={props.patchLoading}
              className='app-layout__form-button'
            >
              {t(dictionary.general.save)}
            </Button>
            <Popconfirm
              placement='left'
              title={t(workersPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onDelete}
            >
              <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                {t(dictionary.general.delete)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default WorkerDataView

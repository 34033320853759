import * as d3 from 'd3'
import { Score } from './types'
import React, { useEffect, useMemo, useRef } from 'react'

type LineProps = {
  xScale: d3.ScaleTime<number, number>
  yScale: d3.ScaleLinear<number, number>
  scores: Score[]
  zoom: [Date, Date]
}

const Line = ({ xScale, yScale, scores, zoom }: LineProps): JSX.Element => {
  const line = useMemo(
    () =>
      d3
        .line<Score>()
        .x((d) => xScale(d.date))
        .y((d) => yScale(d.value))
        .defined((d) => !isNaN(d.value)),
    [xScale, yScale]
  )
  const ref = useRef<SVGPathElement>(null)
  useEffect(() => {
    if (ref.current) {
      line.x((d) => xScale(d.date)).y((d) => yScale(d.value))
      // const downsampled = downsample(scores, zoom, xScale);
      d3.select(ref.current)
        .datum(scores)
        .attr('d', line as any)
    }
  }, [line, ref, scores, zoom])

  return <path stroke='steelblue' fill='none' ref={ref} />
}

export default Line

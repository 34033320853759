import * as d3 from 'd3'
import React, { useEffect, useMemo, useRef } from 'react'

type YAxisProps = {
  yScale: d3.ScaleLinear<number, number>
  width: number
}

const YAxis = ({ yScale, width }: YAxisProps): JSX.Element => {
  const ref = useRef<SVGSVGElement>(null)
  const yAxis = useMemo(() => d3.axisLeft(yScale) as d3.Axis<number>, [yScale])
  useEffect(() => {
    d3.select(ref.current)
      .call(yAxis as any)
      .selectAll('.tick')
      .selectAll('line.grid')
      .data([null])
      .join('line')
      .classed('grid', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)
      .attr('stroke', 'currentColor')
      .attr('opacity', 0.1)
  }, [yAxis, ref])
  return <g className='y-axis' ref={ref}></g>
}

export default YAxis

/* eslint-disable react/display-name */
import { Col, Row, Card, Table, Typography, Statistic, Button } from 'antd'
import React, { FunctionComponent, ReactText, HTMLAttributes, useContext, useMemo } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import Efficiency from '../../../../components/efficiency'
import { reportShowRoutes } from '../../../../containers/Router/routes'
import WorkerDevice from '../../../../components/workerDevice'
import WorkerTimeline from '../../../../components/workerTimeline'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import Map from '../../../../components/map'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'
import { onTableChange } from '../../../../components/useTable'
import FilterTextSearch from '../../../../components/FilterTextSearch'
import { getSelectableFilterColumn } from '../../../../components/getSelectableFilterColumn'
import moment from 'moment'
import JobWorkersCount from '../../../../components/JobWorkersCount'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

import './workerDetails.less'
import FilterTags from '../../../../components/filterTags'
import { GeoData } from '../../../../components/map/mapType'
import { generatePath } from 'react-router-dom'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  jobsPage: any
  t: any
  dictionary: any
  jobWorkersTotal: number
  countRequiredWorkers: (job: Job) => number
}

export const Columns = (props: ColumnProps): ColumnType<Job>[] => {
  const jobStatusSelectValues = useMemo(
    () =>
      Object.keys(props.dictionary.jobStatus).map((jobStatus) => ({
        label: props.t((props.dictionary.jobStatus as any)[jobStatus]),
        value: jobStatus
      })),
    [props.dictionary.jobStatus]
  )

  return [
    {
      title: props.t(props.jobsPage.name),
      dataIndex: 'name',
      sorter: true,
      sortOrder: props?.currentSort['name'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'name'
        })
    },
    {
      title: props.t(props.jobsPage.status),
      dataIndex: 'status',
      sorter: true,
      sortOrder: props?.currentSort['status'],
      ...getSelectableFilterColumn(
        'status',
        props.handleFilter,
        jobStatusSelectValues,
        props.t,
        props.dictionary
      ),
      render: (value): string => props.t(props.dictionary.jobStatus[value])
    },
    {
      title: props.t(props.jobsPage.workers),
      render: (_: string, record: Job): JSX.Element => <JobWorkersCount job={record} />
    },
    {
      title: props.t(props.jobsPage.startDate),
      dataIndex: 'start',
      sorter: true,
      sortOrder: props?.currentSort['start'],
      responsive: ['md'],
      render: (value: Date): any => moment(value).format('HH:mm D-M-YYYY')
    },
    {
      title: props.t(props.jobsPage.endDate),
      dataIndex: 'end',
      sorter: true,
      sortOrder: props?.currentSort['end'],
      responsive: ['md'],
      render: (value: Date): any => moment(value).format('HH:mm D-M-YYYY')
    },
    {
      title: 'Ocena',
      dataIndex: 'score',
      render: (value: number | undefined): string => value?.toFixed(2) || '-'
    }
  ]
}

export interface WorkerDetailsViewProps {
  workerId: string
  worker?: Worker
  jobs?: Job[]
  jobWorkersTotal: number
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Job>
  handleOnRowClick: (id: string) => void
  handleFilter: (value: ReactText, dataIndex: string) => void
  countRequiredWorkers: (job: Job) => number
  geoData: GeoData[]
}

const WorkerDetailsView: FunctionComponent<WorkerDetailsViewProps> = (
  props: WorkerDetailsViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage
  const workersPage = dictionary.workersPage

  const stats = props.worker?.stats
  const efficiencyToday = stats?.today
  const efficiencyYesterday = stats?.yesterday
  const efficiencyWeek = stats?.week
  const efficiencyMonth = stats?.month

  return (
    <ContentLoader loading={!props.worker}>
      <Card>
        <Row className='details-worker'>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Efficiency title={t(workersPage.current)} value={props.worker?.score} raise />
            <Statistic
              title={t(workersPage.deviceId)}
              value={' '}
              prefix={<WorkerDevice deviceId={props.worker?.deviceId} />}
              className='details-worker__statistic'
            />

            <Statistic
              title={t(workersPage.status)}
              className='details-worker__statistic'
              value={' '}
              prefix={props.worker?.active ? t(workersPage.active) : t(workersPage.inActive)}
            />
          </Col>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Statistic
              title={t(workersPage.phoneNumber)}
              className='details-worker__statistic'
              value={' '}
              prefix={
                props.worker?.phoneNumber ? props.worker?.phoneNumber : t(workersPage.missing)
              }
            />
            <Statistic
              title='Raporty'
              className='details-worker__statistic'
              value={' '}
              prefix={
                <>
                  {/* TODO: we might need to do history.push here so the back button works correctly */}
                  <Button
                    type='primary'
                    className='details-worker__button'
                    href={generatePath(reportShowRoutes.workerReport.path, {
                      id: props.workerId
                    })}
                  >
                    Pokaż Raport
                  </Button>
                  <br />
                  <Button
                    type='primary'
                    className='details-worker__button'
                    href={generatePath(reportShowRoutes.workerChart.path, {
                      id: props.workerId
                    })}
                  >
                    Pokaż Wykres
                  </Button>
                </>
              }
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ minHeight: 200 }}>
            <Map geoData={props.geoData} />
          </Col>
        </Row>
      </Card>
      <WorkerTimeline worker={props.worker} />

      <Row justify='space-between'>
        <Col style={{ marginTop: '2rem' }} xs={24} sm={10} md={10} lg={8} xl={6}>
          <Efficiency title={t(workersPage.efficiencyToday)} value={efficiencyToday} />
        </Col>
        <Col style={{ marginTop: '2rem' }} xs={24} sm={10} md={10} lg={8} xl={6}>
          <Efficiency
            title={t(workersPage.efficiencyYesterday)}
            value={efficiencyYesterday}
            raise
          />
        </Col>
        <Col style={{ marginTop: '2rem' }} xs={24} sm={10} md={10} lg={8} xl={6}>
          <Efficiency title={t(workersPage.efficiencyWeek)} value={efficiencyWeek} />
        </Col>
        <Col style={{ marginTop: '2rem' }} xs={24} sm={10} md={10} lg={8} xl={6}>
          <Efficiency title={t(workersPage.efficiencyMonth)} value={efficiencyMonth} />
        </Col>
      </Row>

      <Typography.Title level={3} style={{ marginTop: '2rem' }}>
        {t(workersPage.assignedJobs)}
      </Typography.Title>
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          dictionary: dictionary,
          jobsPage,
          jobWorkersTotal: props.jobWorkersTotal,
          countRequiredWorkers: props.countRequiredWorkers
        })}
        loading={props.loading}
        dataSource={props.jobs}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ContentLoader>
  )
}

export default WorkerDetailsView

import React, { FunctionComponent, HTMLAttributes, ReactText, useContext, useMemo } from 'react'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Table, Typography, DatePicker, Button } from 'antd'
import FilterTags from '../../../../components/filterTags'
import { SortOrder } from 'antd/lib/table/interface'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { ColumnType } from 'antd/es/table'
import { getSelectableFilterColumn } from '../../../../components/getSelectableFilterColumn'
import JobWorkersCount from '../../../../components/JobWorkersCount'
import moment from 'moment'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { onTableChange } from '../../../../components/useTable'
import { RangeValue } from 'rc-picker/lib/interface'
import Moment from 'moment'

import '../../reports.less'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  jobsPage: any
  reportsPage: any
  t: any
  dictionary: any
}

export const Columns = (props: ColumnProps): ColumnType<Job>[] => {
  const jobStatusSelectValues = useMemo(
    () =>
      Object.keys(props.dictionary.jobStatus).map((jobStatus) => ({
        label: props.t((props.dictionary.jobStatus as any)[jobStatus]),
        value: jobStatus
      })),
    [props.dictionary.jobStatus]
  )

  return [
    {
      title: props.t(props.reportsPage.jobName),
      dataIndex: 'name',
      sorter: true,
      sortOrder: props?.currentSort['name']
    },
    {
      title: props.t(props.jobsPage.status),
      dataIndex: 'status',
      sorter: true,
      sortOrder: props?.currentSort['status'],
      ...getSelectableFilterColumn(
        'status',
        props.handleFilter,
        jobStatusSelectValues,
        props.t,
        props.dictionary
      ),
      render: (value): string => props.t(props.dictionary.jobStatus[value])
    },
    {
      title: props.t(props.jobsPage.workers),
      render: function RenderJobWorkersCount(value: string, record: Job): JSX.Element {
        return <JobWorkersCount job={record} />
      }
    },
    {
      title: props.t(props.jobsPage.startDate),
      dataIndex: 'start',
      sorter: true,
      sortOrder: props?.currentSort['start'],
      render: (value: Date): any => moment(value).format('HH:mm DD-MM-YYYY')
    },
    {
      title: props.t(props.jobsPage.endDate),
      dataIndex: 'end',
      sorter: true,
      sortOrder: props?.currentSort['end'],
      render: (value: Date): any => moment(value).format('HH:mm DD-MM-YYYY')
    }
  ]
}

export interface LocationReportViewProps {
  location?: Location
  jobs?: Job[]
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Job>
  handleRedirectToJob: (id: string) => void
  handleFilter: (value: ReactText, dataIndex: string) => void
  handleTimePeriodChange: (range: RangeValue<Moment.Moment>) => void
  loading: boolean
  handleExportToCSV: () => void
  handleExportToPDF: () => void
}

const LocationReportView: FunctionComponent<LocationReportViewProps> = (
  props: LocationReportViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const reportsPage = dictionary.reportsPage
  const jobsPage = dictionary.jobsPage

  return (
    <>
      <Typography.Title level={3} className='reports--title'>
        {props.location?.name}
      </Typography.Title>
      <div className='reports--date-picker'>
        {t(reportsPage.reportForPeriod)}
        <DatePicker.RangePicker
          allowEmpty={[true, true]} //[start, end]
          onCalendarChange={props.handleTimePeriodChange}
        />
      </div>

      <FilterTags skippedKeys={['start', 'end']} />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          jobsPage,
          reportsPage,
          t,
          dictionary
        })}
        loading={props.loading}
        dataSource={props.jobs}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleRedirectToJob(record._id)
          }
        }}
      />
      <div>
        <Button onClick={props.handleExportToCSV}>Pobierz CSV</Button>
        <Button onClick={props.handleExportToPDF}>Pobierz PDF</Button>
      </div>
    </>
  )
}

export default LocationReportView

import { message } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { queryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useCreateMutation, useFindQuery, useGetQuery } from '../../../../query'

const useJobWorkers = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const { id } = useParams<{ id: string }>()

  const { data: job } = useGetQuery('jobs', id)

  const { data: groupsRes } = useFindQuery(
    'groups',
    job?._id
      ? {
          $limit: 1000,
          _id: { $in: job.workersToAssign?.map((workersToAssign) => workersToAssign.groupId) }
        }
      : false
  )

  const [selectedWorker, setSelectedWorker] = useState<string>()

  const { data: jobWorkersRes } = useFindQuery(
    'jobWorkers',
    job
      ? {
          $limit: 10000,
          start: { $lt: job.end },
          end: { $gt: job.start }
        }
      : false
  )

  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workerGroupsRes } = useFindQuery(
    'workerGroups',
    selectedGroup
      ? {
          $limit: 1000,
          groupId: selectedGroup
        }
      : false
  )

  const { data: workers } = useFindQuery(
    'workers',
    workerGroupsRes && jobWorkersRes && job
      ? {
          $or: [
            { firstName: { $regex: workerSearch, $options: 'i' } },
            { lastName: { $regex: workerSearch, $options: 'i' } }
          ],
          _id: {
            $in: workerGroupsRes.data.map((workerGroup) => workerGroup.workerId),
            $nin: jobWorkersRes.data.map((jobWorker) => jobWorker.workerId)
          },
          startDate: { $lte: job.start },
          active: true
        }
      : false
  )

  const [createJobWorker, { status: createJobWorkerStatus }] = useCreateMutation('jobWorkers')

  const onAddWorker = useCallback(async () => {
    try {
      if (!selectedGroup || !selectedGroup) return

      await createJobWorker({
        jobId: id,
        workerId: selectedWorker,
        groupId: selectedGroup,
        start: job?.start,
        end: job?.end
      })

      setSelectedGroup('')
      setSelectedWorker('')

      message.success(t(jobsPage.workerAdded))

      await queryCache.refetchQueries('workerGroups')
    } catch (e) {
      console.log(e)
    }
  }, [selectedWorker, job, jobWorkersRes])

  return {
    job,
    groups: groupsRes?.data,
    selectedGroup,
    setSelectedGroup,
    setWorkerSearch,
    workers: workers?.data,
    onAddWorker,
    createJobWorkerLoading: createJobWorkerStatus === 'loading',
    setSelectedWorker,
    selectedWorker
  }
}

export default useJobWorkers

import React, { useMemo, useCallback } from 'react'
import { Typography, Spin } from 'antd'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { RangeValue } from '../../../../components/timerangeSelectorBar'
import moment from 'moment'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import ScoreGraph from '../../../../components/scorechart/ScoreGraph'
import { JobRange, Score } from '../../../../components/scorechart/types'

type ValidRange = [moment.Moment, moment.Moment]

export type WorkerChartViewProps = {
  worker?: Worker
  range: ValidRange
  jobs: Job[]
  scoresByJob: Map<string, Score[]>
  chartData: any[]
  scores: Score[]
  setRange: (range: RangeValue) => void
}

export const WorkerChartView = (props: WorkerChartViewProps): JSX.Element => {
  const domain: [Date, Date] = [props.range[0].toDate(), props.range[1].toDate()]

  const setDomain = useCallback(
    (domain: [Date, Date]) => {
      props.setRange([moment(domain[0]), moment(domain[1])])
    },
    [props.setRange]
  )

  const jobRanges: Array<JobRange> = useMemo(
    () =>
      props.jobs.map((job) => ({
        id: job._id.toString(),
        start: new Date(job.start),
        end: new Date(job.end),
        name: job.name
      })),
    [props.jobs]
  )

  return (
    <div>
      {!props.worker ? (
        <Spin />
      ) : (
        <ScoreGraph
          title={`${props.worker.firstName} ${props.worker.lastName}`}
          jobs={jobRanges}
          scores={props.scores}
          domain={domain}
          setDomain={setDomain}
        />
      )}
    </div>
  )
}

import { Form, Select } from 'antd'
import React, { useContext } from 'react'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import { Job } from '../../providers/ClientProvider/client/services/jobs/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import './mainMap.less'
export interface FormProps {
  handleSelect: (value: string) => void
  allWorkers: Worker[]
  allSuitableDevices: Device[]
  recentJobs: Job[]
}

export const FormView = (props: FormProps): JSX.Element => {
  const { t, dictionary } = useContext(LocalizationContext)
  return (
    <Form.Item name='Filter' label={t(dictionary.mainMapPage.filter)}>
      <Select
        allowClear
        showSearch
        showArrow
        placeholder={t(dictionary.mainMapPage.formPlaceholder)}
        filterOption
        optionFilterProp={'filter'}
        onChange={props.handleSelect}
      >
        {props.allWorkers?.map((worker) => (
          <Select.Option
            key={`worker#${worker._id}`}
            value={JSON.stringify({ id: worker._id, type: 'worker' })}
            filter={`${worker.firstName} ${worker.lastName}`}
          >
            {worker.firstName} {worker.lastName}{' '}
            <div className='map__option-type'>({t(dictionary.general.worker)})</div>{' '}
          </Select.Option>
        ))}
        {props.recentJobs?.map((job) => (
          <Select.Option
            key={`job#${job._id}`}
            value={JSON.stringify({ id: job._id, type: 'job' })}
            filter={`${job.name}`}
          >
            {job.name}
            <div className='map__option-type'>({t(dictionary.general.job)})</div>{' '}
          </Select.Option>
        ))}
        {props.allSuitableDevices.map((device) => (
          <Select.Option
            key={`device#${device._id}`}
            value={JSON.stringify({ id: device._id, type: 'device' })}
            filter={`${device.imei}`}
          >
            {device.imei}
            <div className='map__option-type'>({t(dictionary.general.device)})</div>{' '}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

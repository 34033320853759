import * as d3 from 'd3'
import { JobRange } from './types'
import React, { useCallback } from 'react'
import { appRoutes } from '../../containers/Router/routes'
import './jobs.css'
import { generatePath, useHistory } from 'react-router-dom'

type JobsProps = {
  jobs: JobRange[]
  xScale: d3.ScaleTime<number, number>
  yScale: d3.ScaleLinear<number, number>
  zoom: [Date, Date]
}

const Jobs = ({ jobs, xScale, yScale }: JobsProps): JSX.Element => {
  const history = useHistory()
  const openJobPage = useCallback(
    (id: string): void => {
      const url = generatePath(appRoutes.jobShow.routes.details.path, { id })
      window.open(url, '_blank')
    },
    [history]
  )
  return (
    <g className='jobs'>
      {jobs.map((job) => (
        <g key={job.id} transform={`translate(${xScale(job.start)}, 0)`}>
          <rect
            className='job-rect'
            width={xScale(job.end) - xScale(job.start)}
            height={yScale(0)}
            fill='rgba(255, 0, 0, 0.05)'
            stroke='red'
            onClick={(): void => openJobPage(job.id)}
          />
          <text
            x='0'
            y='0'
            dy='1em'
            textAnchor='middle'
            transform={`translate(-20, ${yScale(0) / 2}) rotate(-90)`}
            fill='currentColor'
          >
            {job.name}
          </text>
        </g>
      ))}
    </g>
  )
}

export default Jobs

import React, { FunctionComponent, ReactText, useContext } from 'react'
import { Table, Button } from 'antd'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import FilterTextSearch from '../../../../components/FilterTextSearch'
import FilterTags from '../../../../components/filterTags'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { appRoutes } from '../../../../containers/Router/routes'
import { generatePath, useHistory } from 'react-router-dom'
import { onTableChange } from '../../../../components/useTable'

interface ColumnProps {
  dictionary: any
  t: any
  handleFilter: (value: ReactText, dataIndex: string) => void
  handleOnRowClick: (worker: Worker) => void
}

export const Columns = (props: ColumnProps): ColumnType<Worker>[] => {
  return [
    {
      title: props.t(props.dictionary.workersPage.firstName),
      dataIndex: 'firstName'
    },
    {
      title: props.t(props.dictionary.workersPage.lastName),
      dataIndex: 'lastName',
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'lastName'
        })
    },
    {
      title: props.t(props.dictionary.workersPage.phoneNumber),
      dataIndex: 'phoneNumber'
    },
    {
      title: props.t(props.dictionary.reportsPage.actions),
      render: function GenerateReportButton(worker: Worker): JSX.Element {
        const history = useHistory()

        const goToChart = (): void => {
          history.push(
            generatePath(appRoutes.reportsShow.routes.workerChart.path, { id: worker._id })
          )
        }

        return (
          <div>
            <Button onClick={(): void => props.handleOnRowClick(worker)}>
              {props.t(props.dictionary.reportsPage.generateReport)}
            </Button>
            <Button onClick={goToChart}>Pokaż wykres</Button>
          </div>
        )
      }
    }
  ]
}

export interface WorkerListViewProps {
  loading: boolean
  total: number
  currentPage: number
  onTableChange: onTableChange<Worker>
  workers: Worker[] | undefined
  handleOnRowClick: (value: Worker) => void
  handleFilter: (value: ReactText, dataIndex: string) => void
}

const WorkerListView: FunctionComponent<WorkerListViewProps> = (props: WorkerListViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <>
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        dataSource={props.workers}
        columns={Columns({
          t,
          dictionary,
          handleFilter: props.handleFilter,
          handleOnRowClick: props.handleOnRowClick
        })}
        loading={props.loading}
        rowKey='_id'
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onChange={props.onTableChange}
      />
    </>
  )
}

export default WorkerListView

import { Form, message } from 'antd'
import moment from 'moment'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { JobStatus } from '../../../../providers/ClientProvider/client/services/jobs/JobStatus.enum'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useDeleteMutation, useFindQuery, useGetQuery, usePatchMutation } from '../../../../query'
import ClientContext from '../../../../providers/ClientProvider/client'

const useJobDetails = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: job } = useGetQuery('jobs', id)

  const { data: jobTask } = useGetQuery('tasks', job?.taskId)

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const [patchJob, { status: patchStatus }] = usePatchMutation('jobs')

  const [deleteJobWorker, { status: deleteJobWorkerStatus }] = useDeleteMutation('jobWorkers')

  const { data: jobWorkersRes } = useFindQuery(
    'jobWorkers',
    id
      ? {
          jobId: id
        }
      : false
  )

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useMemo(() => {
    if (!job) return
    setStartDate(job.start)
    setEndDate(job.end)
  }, [job])

  const jobDuration = useMemo(() => {
    if (!job) return

    const duration = moment.duration(moment(job.end).diff(job.start))

    const durationString = duration.days() ? `${duration.days()} ${t(jobsPage.days)} ` : ''

    return (
      durationString +
      `${duration.hours()} ${t(jobsPage.hours)} ${duration.minutes()} ${t(jobsPage.minutes)}`
    )
  }, [job])

  const onFinish = useCallback(async () => {
    if (!id) {
      throw new Error(t(jobsPage.incorrectId))
    }

    try {
      await patchJob({
        id,
        data: {
          ...form.getFieldsValue(),
          start: startDate,
          end: endDate
        }
      })

      if (jobWorkersRes) {
        for (let i = 0; i < jobWorkersRes?.data.length; i++) {
          await deleteJobWorker(jobWorkersRes?.data[i]._id)
        }
      }

      message.success(t(jobsPage.updated))
    } catch (e) {
      console.log(e)
    }
  }, [id, startDate, endDate, jobWorkersRes])

  const onArchive = useCallback(async () => {
    if (!id) {
      throw new Error(t(jobsPage.incorrectId))
    }

    try {
      await patchJob({
        id,
        data: {
          status: JobStatus.Archived
        }
      })

      history.push(appRoutes.jobs.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  const geoData = useMemo(() => {
    if (!job) return []

    return job.mapData?.concat(job.geoData)
  }, [job])

  const client = useContext(ClientContext)

  const startJob = useCallback(async () => {
    const response = await client.services.jobs.find({
      query: { id, startJob: true }
    })
    history.push(appRoutes.jobs.path)
  }, [id])

  return {
    job,
    jobTask,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading' || deleteJobWorkerStatus === 'loading',
    onArchive,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    jobDuration,
    workers: workersRes?.data,
    setWorkerSearch,
    geoData,
    startJob
  }
}
export default useJobDetails

import { ReactText, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFindQuery } from '../../../../query'
import useTable from '../../../../components/useTable'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import qs from 'qs'
import Moment from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { WorkerReportViewProps } from './WorkerReportView'

export const useWorkerReport = (): WorkerReportViewProps => {
  const [timePeriod, setTimePeriod] = useState<RangeValue<Moment.Moment>>()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data: worker } = useFindQuery(
    'workers',
    id
      ? {
          _id: id,
          $limit: 1,
          getDeviceId: true,
          getFullExternalData: true
        }
      : false
  )

  const { query, onTableChange, currentSort, currentPage } = useTable<Job>()

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = {
        $regex: value,
        $options: 'ix'
      }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  useEffect(() => {
    query['start'] = timePeriod?.[0] ? { $gte: timePeriod?.[0].toDate() } : undefined
    query['end'] = timePeriod?.[1] ? { $lte: timePeriod?.[1].toDate() } : undefined
    history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
  }, [timePeriod])

  return {
    workerId: id,
    worker: worker?.data[0],
    handleTimePeriodChange: setTimePeriod,
    total: worker?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleFilter
  }
}

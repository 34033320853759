import { useParams } from 'react-router-dom'
import { useFindQuery } from '../../../../query'
import moment from 'moment'
import { useMemo } from 'react'

const useDeviceDetails = () => {
  const { id } = useParams<{ id: string }>()

  const startDate = useMemo(() => moment().subtract(24, 'hours').toISOString(), [])
  const endDate = useMemo(() => moment().toISOString(), [])

  const { data: device } = useFindQuery(
    'devices',
    id
      ? {
          _id: id,
          $limit: 1,
          getBasicExternalData: true,
          getPositions: true,
          startDate,
          endDate
        }
      : false
  )

  console.log({ device })

  return {
    device: device?.data[0]
  }
}

export default useDeviceDetails

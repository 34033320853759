import React, { FunctionComponent, ReactText, useContext } from 'react'
import { Button, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import FilterTags from '../../../../components/filterTags'
import FilterTextSearch from '../../../../components/FilterTextSearch'

interface ColumnProps {
  dictionary: any
  t: any
  goToReport: (value: Location) => void
  handleFilter: (value: ReactText, dataIndex: string) => void
}

const Columns = (props: ColumnProps): ColumnType<Location>[] => {
  return [
    {
      title: props.t(props.dictionary.locationsPage.name),
      dataIndex: 'name',
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'name'
        })
    },
    {
      title: props.t(props.dictionary.reportsPage.actions),
      render: function GenerateReportButton(location: Location): JSX.Element {
        return (
          <Button onClick={(): void => props.goToReport(location)}>
            {props.t(props.dictionary.reportsPage.generateReport)}
          </Button>
        )
      }
    }
  ]
}

export interface LocationListViewProps {
  loading: boolean
  total: number
  currentPage: number
  Locations: Location[] | undefined
  handleFilter: (value: ReactText, dataIndex: string) => void
  goToReport: (value: Location) => void
  onTableChange: onTableChange<Location>
}

const LocationListView: FunctionComponent<LocationListViewProps> = (
  props: LocationListViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  return (
    <>
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        dataSource={props.Locations}
        columns={Columns({
          t,
          dictionary,
          goToReport: props.goToReport,
          handleFilter: props.handleFilter
        })}
        loading={props.loading}
        rowKey='_id'
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onChange={props.onTableChange}
      />
    </>
  )
}

export default LocationListView

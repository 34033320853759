import { useParams } from 'react-router-dom'
import { useGetQuery } from '../../../../query'

const useTaskMap = () => {
  const { id } = useParams<{ id: string }>()

  const { data: task } = useGetQuery('tasks', id)

  return { task }
}
export default useTaskMap

import { CheckCircleFilled, ClockCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Col, Form, Row, Select, Typography, Statistic } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { FunctionComponent, useContext } from 'react'
import ContentLoader from '../../components/ContentLoader'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface HomeViewProps {
  formAssign: FormInstance
  formTurnOver: FormInstance
  onFinishAssign: (v: any) => void
  onFinishTurnOver: (v: any) => void
  createLoading: boolean
  turnOverLoading: boolean
  workers?: Worker[]
  workersLoading: boolean
  devices?: Device[]
  devicesToTurnOver?: Device[]
  setWorkerSearch: (value: string) => void
  setDeviceSearch: (value: string) => void
  setDeviceToTurnOverSearch: (value: string) => void
  jobsPendingTotal: number
  jobsPendingLoading: boolean
  jobsStartedTotal: number
  jobsStartedLoading: boolean
  jobsFinishedLoading: boolean
  jobsFinishedTotal: number
}

const HomeView: FunctionComponent<HomeViewProps> = (props: HomeViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Typography.Title level={4}>{t(dictionary.general.assign)}</Typography.Title>
        <Form
          form={props.formAssign}
          name='assign-device'
          onFinish={props.onFinishAssign}
          layout='vertical'
        >
          <Form.Item
            name='workerId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.worker)}
          >
            <Select
              showSearch
              showArrow
              placeholder={t(dictionary.general.chooseFromList)}
              filterOption={false}
              onSearch={props.setWorkerSearch}
            >
              {props.workers?.map((worker) => (
                <Select.Option key={worker._id} value={worker._id}>
                  {worker.firstName} {worker.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='deviceId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.device)}
          >
            <Select
              showSearch
              showArrow
              placeholder={'IMEI'}
              filterOption={false}
              onSearch={props.setDeviceSearch}
            >
              {props.devices?.map((device: Device) => (
                <Select.Option key={device._id} value={device._id}>
                  {device.imei}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='app-layout__form-button'
            loading={props.createLoading}
          >
            {t(dictionary.general.assign)}
          </Button>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Typography.Title level={4}>{t(dictionary.general.turnOver)}</Typography.Title>
        <Form
          form={props.formTurnOver}
          name='turn-over-device'
          onFinish={props.onFinishTurnOver}
          layout='vertical'
        >
          <Form.Item
            name='deviceId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.device)}
          >
            <Select
              showSearch
              showArrow
              placeholder={'IMEI'}
              filterOption={false}
              onSearch={props.setDeviceToTurnOverSearch}
            >
              {props.devicesToTurnOver?.map((device: Device) => (
                <Select.Option key={device._id} value={device._id}>
                  {device.imei}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='app-layout__form-button'
            loading={props.turnOverLoading}
          >
            {t(dictionary.general.turnOver)}
          </Button>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Typography.Title level={4}>{t(dictionary.homePage.jobs)}</Typography.Title>
        <Row>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <ContentLoader loading={props.jobsPendingLoading}>
              <Statistic
                title={
                  <>
                    <ExclamationCircleFilled style={{ marginRight: 8, color: '#d9001b' }} />
                    {t(dictionary.homePage.pending)}
                  </>
                }
                value={props.jobsPendingTotal || 0}
              />
            </ContentLoader>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <ContentLoader loading={props.jobsStartedLoading}>
              <Statistic
                title={
                  <>
                    <ClockCircleFilled style={{ marginRight: 8, color: '#0F9DE9' }} />
                    {t(dictionary.homePage.started)}
                  </>
                }
                value={props.jobsStartedTotal || 0}
              />
            </ContentLoader>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <ContentLoader loading={props.jobsFinishedLoading}>
              <Statistic
                title={
                  <>
                    <CheckCircleFilled style={{ marginRight: 8, color: '#70b603' }} />
                    {t(dictionary.homePage.finished)}
                  </>
                }
                value={props.jobsFinishedTotal || 0}
              />
            </ContentLoader>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HomeView

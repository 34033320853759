import React from 'react'

import EfficiencyView from './EfficiencyView'
import useEfficiency from './useEfficiency'

interface EfficiencyProps {
  title: string
  raise?: boolean
  value?: number
}

const Efficiency = (props: EfficiencyProps) => {
  const state = useEfficiency({ ...props })

  return <EfficiencyView {...state} {...props} />
}

export default Efficiency

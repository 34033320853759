import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useFindQuery } from '../../../../query'
import { useMemo, useState } from 'react'
import { WorkerChartViewProps } from './WorkerChartView'
import { RangeValue } from '../../../../components/timerangeSelectorBar'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'

type RawScore = NonNullable<Worker['scores']>[number]

const keepUnique = <T>(arr: T[]): T[] => Array.from(new Set(arr))

const groupScoresByJob = (scores: RawScore[], jobs: Job[]): Map<string, RawScore[]> => {
  type ScoreWithParsedTime = RawScore & { time: moment.Moment }
  const scoresWithParsedTime: ScoreWithParsedTime[] = scores.map((score) => ({
    ...score,
    time: moment(score.dateAndHour)
  }))
  const isScoreInJob = (score: ScoreWithParsedTime, job: Job): boolean =>
    score.time.isBetween(job.start, job.end)
  const scoresByJob = new Map<string, RawScore[]>(
    jobs.map((job) => [job._id, scoresWithParsedTime.filter((score) => isScoreInJob(score, job))])
  )
  return scoresByJob
}

type ChartDataPoint = {
  date: number
  jobName?: string
  value: number
  isSeparator?: boolean
}

const prepareChartData = (scoresByJob: Map<string, RawScore[]>, jobs: Job[]): ChartDataPoint[] => {
  const dataPoints: ChartDataPoint[] = []

  for (const [jobId, scores] of scoresByJob.entries()) {
    const job = jobs.find((job) => job._id === jobId)

    if (!job) {
      continue
    }

    for (const score of scores) {
      dataPoints.push({
        date: new Date(score.dateAndHour).getTime(),
        jobName: job.name,
        value: score.average
      })
    }
  }

  // we want to add null points between each job to make sure that the line chart is not connecting points from different jobs
  // step 1: sort jobs by start date
  // step 2: add null points between each job
  const sortedJobs = jobs.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())

  for (let i = 0; i < sortedJobs.length - 1; i++) {
    const job = sortedJobs[i]
    const nextJob = sortedJobs[i + 1]
    const dateInBetween = new Date(
      new Date(job.end).getTime() / 2 + new Date(nextJob.start).getTime() / 2
    )
    dataPoints.push({ date: dateInBetween.getTime(), value: 0, isSeparator: true })
  }

  return dataPoints.sort((a, b) => a.date - b.date)
}

const useWorkerJobs = (workerId: string, start: moment.Moment, end: moment.Moment): Job[] => {
  const { data: jobWorkersRes } = useFindQuery(
    'jobWorkers',
    workerId !== ''
      ? {
          workerId,
          start: { $lte: end.toISOString() },
          end: { $gte: start.toISOString() }
        }
      : false
  )
  const jobWorkers = jobWorkersRes?.data || []

  const jobIds = keepUnique(jobWorkers.map((jobWorker) => jobWorker.jobId))

  const { data: jobsRes } = useFindQuery(
    'jobs',
    jobIds.length > 0 ? { _id: { $in: jobIds } } : false
  )
  const jobs = jobsRes?.data || []

  return jobs
}

export const useWorkerChart = (): WorkerChartViewProps => {
  const { id } = useParams<{ id: string }>()
  const defaultStart = useMemo(() => moment().subtract(30, 'days'), [])
  const defaultEnd = useMemo(() => moment(), [])
  const [range, setRange] = useState<RangeValue>([defaultStart, defaultEnd])

  const start = moment(range ? range[0] : defaultStart)
  const end = moment(range ? range[1] : defaultEnd)

  const { data: workerRes } = useFindQuery(
    'workers',
    id
      ? {
          _id: id,
          $limit: 1,
          getScores: true,
          scoresStartDate: start.toISOString(),
          scoresEndDate: end.toISOString()
        }
      : false
  )

  const rawScores: RawScore[] = workerRes?.data[0]?.scores || []

  const jobs = useWorkerJobs(id, start, end)

  const scoresByJob = useMemo(() => groupScoresByJob(rawScores, jobs), [rawScores, jobs])

  const chartData = useMemo(() => prepareChartData(scoresByJob, jobs), [scoresByJob, jobs])

  const scores = rawScores.map((score) => ({
    date: new Date(score.dateAndHour),
    value: score.average
  }))

  return {
    worker: workerRes?.data[0],
    range: [start, end],
    jobs,
    scoresByJob,
    setRange,
    chartData,
    scores
  }
}

import { Button, Row, Typography } from 'antd'
import React, { FunctionComponent } from 'react'

import './listLayout.less'

interface ListLayoutViewProps {
  children?: React.ReactNode
  title: string
  buttonText?: string
  buttonAction?: () => void
}

const ListLayoutView: FunctionComponent<ListLayoutViewProps> = (props) => {
  return (
    <div className='list-layout'>
      <Row className='list-layout__header'>
        <Typography.Title>{props.title}</Typography.Title>
        {props.buttonText && props.buttonAction && (
          <Button type='primary' className='list-layout__button' onClick={props.buttonAction}>
            {props.buttonText}
          </Button>
        )}
      </Row>
      {props.children}
    </div>
  )
}

export default ListLayoutView

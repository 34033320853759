import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import useTable from '../../../../components/useTable'
import { WorkerDevice } from '../../../../providers/ClientProvider/client/services/workerDevices/types'
import { useFindQuery } from '../../../../query'

const useDeviceData = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<WorkerDevice>()

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const { data: workerDevicesRes, status: workerDeviceStatus } = useFindQuery('workerDevices', {
    $sort: { assignedDate: '-1' },
    ...query,
    deviceId: id
  })

  return {
    workerDevices: workerDevicesRes?.data,
    form,
    loading: workerDeviceStatus === 'loading',
    total: workerDevicesRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage
  }
}
export default useDeviceData

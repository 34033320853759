import { useState, useEffect } from 'react'

type UseAsyncResult<T, Fallback> =
  | {
      data: T
      loading: false
    }
  | {
      data: Fallback
      loading: true
    }

export const useAsync = <T extends any, Fallback extends any>(
  fn: (signal: AbortSignal) => Promise<T>,
  fallback: Fallback
): UseAsyncResult<T, Fallback> => {
  const [result, setResult] = useState<UseAsyncResult<T, Fallback>>({
    data: fallback,
    loading: true
  })
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    setResult({ data: fallback, loading: true })
    fn(signal).then((data) => {
      if (!signal.aborted) setResult({ data, loading: false })
    })

    return (): void => {
      controller.abort()
    }
  }, [fn])
  return result
}

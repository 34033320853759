import React, { FunctionComponent, useContext, useEffect } from 'react'
import moment from 'moment'
import ContentLoader from '../../../../components/ContentLoader'
import { Device } from '../../../../providers/ClientProvider/client/services/devices/types'
import Map from '../../../../components/map'
import { DatePicker, Collapse, Button } from 'antd'

import './deviceMap.less'
import { shapeType } from '../../../../components/map/geomanShape.enum'
import ClientContext from '../../../../providers/ClientProvider/client'
import { coordinates } from '../../../../components/map/mapType'

interface DeviceMapViewProps {
  device?: Device
}

type RangeValue = [moment.Moment | null, moment.Moment | null] | null

const DeviceMapView: FunctionComponent<DeviceMapViewProps> = (props: DeviceMapViewProps) => {
  const geoData = props.device?.geoData
    ? props.device.geoData
    : props.device?.lastLocation
    ? [props.device.lastLocation]
    : undefined

  const [positions, setPositions] = React.useState<coordinates[]>([])
  const [rangeValue, setRangeValue] = React.useState<RangeValue>(null)

  const client = useContext(ClientContext)

  useEffect(() => {
    if (rangeValue === null || rangeValue[0] === null || rangeValue[1] === null) {
      setPositions([])
      return
    }

    // positions in response
    interface PosRes {
      latitude: number
      longitude: number
      when: string
    }

    const cmp = (a: PosRes, b: PosRes): number =>
      new Date(a.when).getTime() - new Date(b.when).getTime()
    const toCoords = (a: PosRes): coordinates => [a.latitude, a.longitude]

    console.log('services', client.services)
    client.services.devices
      .find({
        query: {
          _id: props.device?._id,
          getPositions: true,
          $limit: 1,
          startDate: rangeValue[0].toISOString(),
          endDate: rangeValue[1].toISOString()
        }
      })
      .then(({ data }) => (data[0] as any).positions as PosRes[])
      .then((data) => data.sort(cmp))
      .then((data) => data.map(toCoords))
      .then((data) => setPositions(data))
  }, [rangeValue])

  const geoDataWithHistory = geoData?.concat({
    geometry: {
      type: shapeType.LineString,
      coordinates: positions
    },
    type: 'Feature'
  })

  console.log('positions', positions)

  const lastNDays = (n: number): RangeValue => [moment().subtract(n, 'day'), moment()]
  const setLast24h = (): void => setRangeValue(lastNDays(1))
  const setLast7d = (): void => setRangeValue(lastNDays(7))
  const setLast30d = (): void => setRangeValue(lastNDays(30))
  const setNoRange = (): void => setRangeValue(null)

  return (
    <div className='device-map'>
      <ContentLoader loading={!props.device}>
        <Collapse>
          <Collapse.Panel header='Wyswietlanie trasy' key='1'>
            <DatePicker.RangePicker showTime value={rangeValue} onChange={setRangeValue} />
            <Button onClick={setLast24h}>Ostatnie 24h</Button>
            <Button onClick={setLast7d}>Ostatnie 7 dni</Button>
            <Button onClick={setLast30d}>Ostatnie 30 dni</Button>
            <Button onClick={setNoRange}>Nie pokazuj</Button>
          </Collapse.Panel>
        </Collapse>
        <Map geoData={geoDataWithHistory} />
      </ContentLoader>
    </div>
  )
}

export default DeviceMapView

import React from 'react'

import DeviceDataView from './DeviceDataView'
import useDeviceData from './useDeviceData'

const DeviceData = (): JSX.Element => {
  const state = useDeviceData()

  return <DeviceDataView {...state} />
}

export default DeviceData

import React, { FunctionComponent } from 'react'

import ReportShowLayoutView from './ReportShowLayoutView'
import useReportShowLayout from './useReportShowLayout'

interface TaskShowLayoutProps {
  children?: React.ReactNode
}

const ReportsShowLayout: FunctionComponent<TaskShowLayoutProps> = (props: TaskShowLayoutProps) => {
  const listLayoutState = useReportShowLayout()

  return <ReportShowLayoutView {...props} {...listLayoutState} />
}

export default ReportsShowLayout

import { Form } from 'antd'
import moment from 'moment'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { useCreateMutation } from '../../../query'

const useWorkerCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [createWorker, { status: createStatus }] = useCreateMutation('workers')

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()

      const startDateMoment = moment(values.startDate)
      values.startDate = new Date(
        Date.UTC(startDateMoment.year(), startDateMoment.month(), startDateMoment.date())
      )

      if (values.endDate) {
        const endDateMoment = moment(values.endDate)
        values.endDate = new Date(
          Date.UTC(endDateMoment.year(), endDateMoment.month(), endDateMoment.date(), 23, 59, 59)
        )
      }

      const worker = await createWorker({
        ...values
      })

      history.replace(
        generatePath(appRoutes.workerShow.routes.data.path, {
          id: worker._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    form,
    onFinish,
    createLoading: createStatus === 'loading',
    timezone
  }
}
export default useWorkerCreate

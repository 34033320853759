import WorkerListView from './WorkerListView'
import React from 'react'
import useWorkerList from './useWorkerList'

export const WorkerList = (): JSX.Element => {
  const state = useWorkerList({ manager: false })
  return <WorkerListView {...state} />
}
export const ManagerList = (): JSX.Element => {
  const state = useWorkerList({ manager: true })
  return <WorkerListView {...state} />
}

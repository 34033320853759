import React, { FunctionComponent, useContext } from 'react'
import { Col, Row, Card, Statistic } from 'antd'
import moment from 'moment'

import './deviceDetails.less'

import ContentLoader from '../../../../components/ContentLoader'
import { Device } from '../../../../providers/ClientProvider/client/services/devices/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { ThunderboltOutlined, WifiOutlined, SendOutlined } from '@ant-design/icons'
import Map from '../../../../components/map'

interface DeviceDetailsViewProps {
  device?: Device
}

const DeviceDetailsView: FunctionComponent<DeviceDetailsViewProps> = (
  props: DeviceDetailsViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage
  const geoData = props.device?.geoData
    ? props.device.geoData
    : props.device?.lastLocation
    ? [props.device.lastLocation]
    : undefined

  return (
    <ContentLoader loading={!props.device}>
      <Card>
        <Row className='details-device'>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            {/* <Statistic */}
            {/*   className='efficiency' */}
            {/*   title={t(devicesPage.efficiency)} */}
            {/*   value={props.device?.activityStats || 0} */}
            {/* /> */}
            <Statistic
              title={t(devicesPage.lastUpdate)}
              value={' '}
              className='details-device__statistic'
              prefix={
                props.device?.lastUpdate ? (
                  <>{moment(props.device?.lastUpdate).format('HH:mm D-M-YYYY')}</>
                ) : (
                  <>N/A</>
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            <Statistic
              title={t(devicesPage.deviceId)}
              className='details-device__statistic'
              value={' '}
              prefix={props.device?.imei}
            />
            <Statistic
              title={t(devicesPage.device)}
              className='details-device__statistic'
              value={' '}
              prefix={
                <Row>
                  <Col xs={24} sm={8} md={12} xl={8}>
                    <ThunderboltOutlined />
                    {props.device?.lastUpdate ? <> {props.device?.battery} %</> : <> N/A</>}
                  </Col>
                  <Col xs={24} sm={8} md={12} xl={8}>
                    <WifiOutlined />
                    {props.device?.lastUpdate ? <> {props.device?.gsm} %</> : <> N/A</>}
                  </Col>
                  <Col xs={24} sm={8} md={12} xl={8}>
                    <SendOutlined style={{ transform: 'rotate(-90deg)' }} />
                    {props.device?.gps ? <> {props.device?.gps}</> : <> N/A</>}
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ minHeight: 200 }}>
            <Map geoData={geoData} />
          </Col>
        </Row>
      </Card>
    </ContentLoader>
  )
}

export default DeviceDetailsView

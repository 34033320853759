import React, { FunctionComponent } from 'react'

import ListLayoutView from './ListLayoutView'
import useListLayout from './useListLayout'

interface ListLayoutProps {
  children?: React.ReactNode
  title: string
  buttonText?: string
  buttonAction?: () => void
}

const ListLayout: FunctionComponent<ListLayoutProps> = (props: ListLayoutProps) => {
  const listLayoutState = useListLayout()

  return <ListLayoutView {...props} {...listLayoutState} />
}

export default ListLayout

import React, { useMemo } from 'react'
import { Job } from '../providers/ClientProvider/client/services/jobs/types'
import { useFindQuery } from '../query'
import { Spin, Typography } from 'antd'

const JobWorkersCount = (props: { job: Job }): JSX.Element => {
  const allRequiredWorkers = useMemo(
    () => props.job.workersToAssign?.reduce((acc, group) => acc + group.numberOfWorkers, 0),
    []
  )

  const { data: jobWorkersRes, status: findJobWorkersStatus } = useFindQuery('jobWorkers', {
    $limit: 0,
    jobId: props.job._id,
    active: true
  })

  if (findJobWorkersStatus === 'loading') {
    return <Spin size='small' />
  }

  return (
    <>
      <Typography.Text
        type={jobWorkersRes && jobWorkersRes?.total < allRequiredWorkers ? 'danger' : undefined}
      >
        {jobWorkersRes?.total} / {allRequiredWorkers}
      </Typography.Text>
    </>
  )
}

export default JobWorkersCount

import { message } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { queryCache } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import useTable from '../../../../components/useTable'
import { appRoutes } from '../../../../containers/Router/routes'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useCreateMutation, useDeleteMutation, useFindQuery } from '../../../../query'

const useTaskLocations = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { query, onTableChange, currentSort, currentPage } = useTable<Location>()

  const { data: taskLocationsRes, status: findTaskLocationsStatus } = useFindQuery(
    'taskLocations',
    {
      ...query,
      taskId: id,
      active: true
    }
  )

  const { data: assignedLocationsRes, status: findAssignedLocationsStatus } = useFindQuery(
    'locations',
    taskLocationsRes && taskLocationsRes.total
      ? {
          _id: { $in: taskLocationsRes?.data.map((taskLocation) => taskLocation.locationId) }
        }
      : false
  )

  const [locationSearch, setLocationSearch] = useState<string>('')

  const { data: availableLocationsRes, status: findAvailableLocationsStatus } = useFindQuery(
    'locations',
    taskLocationsRes
      ? {
          $limit: 10,
          _id: { $nin: taskLocationsRes?.data.map((taskLocation) => taskLocation.locationId) },
          name: { $regex: locationSearch, $options: 'i' },
          active: true
        }
      : false
  )

  const [createTaskLocations, { status: createTaskLocationsStatus }] =
    useCreateMutation('taskLocations')

  const [deleteTaskLocation, { status: deleteTaskLocationStatus }] =
    useDeleteMutation('taskLocations')

  const [selectedLocation, setSelectedLocation] = useState<string>()

  const onAddLocation = useCallback(async () => {
    try {
      await createTaskLocations({
        locationId: selectedLocation,
        taskId: id
      })

      setLocationSearch('')

      message.success(t(tasksPage.locationAdded))

      await queryCache.refetchQueries('taskLocations')
      await queryCache.refetchQueries('locations')
      await queryCache.refetchQueries(id)
    } catch (e) {
      console.log(e)
    }
  }, [selectedLocation])

  const onDeleteLocation = useCallback(
    async (locationId: string) => {
      try {
        if (!taskLocationsRes) return

        const taskLocationToDelete = taskLocationsRes.data.find(
          (taskLocation) => taskLocation.locationId === locationId
        )
        if (!taskLocationToDelete) return

        await deleteTaskLocation(taskLocationToDelete._id)

        message.success(t(tasksPage.locationRemoved))

        await queryCache.refetchQueries('taskLocations')
        await queryCache.refetchQueries('locations')
        await queryCache.refetchQueries(id)
      } catch (e) {
        console.log(e)
      }
    },
    [taskLocationsRes]
  )

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(appRoutes.locationShow.routes.details.path, {
        id: id
      })
    )
  }, [])

  return {
    locations: assignedLocationsRes?.data || [],
    locationsLoading:
      findAssignedLocationsStatus === 'loading' || findTaskLocationsStatus === 'loading',
    availableLocations: availableLocationsRes?.data,
    availableLocationsLoading: findAvailableLocationsStatus === 'loading',
    setSelectedLocation,
    onAddLocation,
    createTaskLocationLoading: createTaskLocationsStatus === 'loading',
    onDeleteLocation,
    deleteTaskLocationLoading: deleteTaskLocationStatus === 'loading',
    onTableChange,
    currentSort,
    currentPage,
    taskLocations: taskLocationsRes?.data || [],
    total: taskLocationsRes?.total || 0,
    handleOnRowClick,
    setLocationSearch
  }
}
export default useTaskLocations

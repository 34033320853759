import React, { FunctionComponent, useContext, useMemo } from 'react'
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './jobDetails.less'

import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { JobStatus } from '../../../../providers/ClientProvider/client/services/jobs/JobStatus.enum'
import moment from 'moment'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import Map from '../../../../components/map'
import { GeoData } from '../../../../components/map/mapType'
import { generatePath, Link } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { Task } from '../../../../providers/ClientProvider/client/services/tasks/types'

interface JobDetailsViewProps {
  job?: Job
  jobTask: Task
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onArchive: () => void
  startDate?: Date
  setStartDate: (date: Date) => void
  endDate?: Date
  setEndDate: (date: Date | undefined) => void
  jobDuration?: string
  workers?: Worker[]
  setWorkerSearch: (date: string) => void
  geoData?: GeoData[]
  startJob: () => void
}

const JobDetailsView: FunctionComponent<JobDetailsViewProps> = (props: JobDetailsViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const canBeStarted = useMemo(() => {
    const job = props.job
    if (!job) return false
    const isPending = job.status === JobStatus.Pending
    const isInvalid = job.status === JobStatus.Invalid
    const notEnded = new Date(job.end).getTime() > Date.now()
    return notEnded && (isPending || isInvalid)
  }, [props.job?.status])

  return (
    <div className='edit-job'>
      <ContentLoader loading={!props.job}>
        <Form
          form={props.form}
          name='edit-job'
          initialValues={props.job}
          layout='vertical'
          className='app-layout__form'
        >
          <Row className='edit-job__map'>
            <Map geoData={props.geoData} />
          </Row>
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Typography.Title level={4}>{t(jobsPage.taskInformation)}</Typography.Title>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(jobsPage.name)}
              >
                <Input
                  placeholder={t(jobsPage.name)}
                  type='text'
                  disabled={props.job?.status !== JobStatus.Pending}
                />
              </Form.Item>
              <Form.Item name='client' label={t(jobsPage.client)}>
                <Input
                  placeholder={t(jobsPage.client)}
                  type='text'
                  disabled={props.job?.status !== JobStatus.Pending}
                />
              </Form.Item>
              <Form.Item name='description' label={t(jobsPage.description)}>
                <Input.TextArea
                  placeholder={t(jobsPage.description)}
                  rows={4}
                  disabled={props.job?.status !== JobStatus.Pending}
                />
              </Form.Item>
              <Form.Item name='contractNumber' label={t(jobsPage.contractNumber)}>
                <Input
                  placeholder={t(jobsPage.contractNumber)}
                  type='text'
                  disabled={props.job?.status !== JobStatus.Pending}
                />
              </Form.Item>
              <Form.Item name='coordinator' label={t(jobsPage.coordinator)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  disabled={props.job?.status !== JobStatus.Pending}
                  optionFilterProp={'filter'}
                >
                  {props.workers &&
                    props.workers.map((worker) => (
                      <Select.Option
                        key={worker._id}
                        value={worker._id}
                        filter={`${worker.firstName} ${worker.lastName}`}
                      >
                        {worker.firstName} {worker.lastName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name='contactPerson' label={t(jobsPage.contactPerson)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  disabled={props.job?.status !== JobStatus.Pending}
                  optionFilterProp={'filter'}
                >
                  {props.workers &&
                    props.workers.map((worker) => (
                      <Select.Option
                        key={worker._id}
                        value={worker._id}
                        filter={`${worker.firstName} ${worker.lastName}`}
                      >
                        {worker.firstName} {worker.lastName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name='task' label={t(jobsPage.task)}>
                {props.jobTask && (
                  <Link
                    to={generatePath(appRoutes.taskShow.routes.details.path, {
                      id: props.jobTask._id
                    })}
                  >
                    {props.jobTask.name}
                  </Link>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Typography.Title level={4}>{t(jobsPage.jobTime)}</Typography.Title>
              <Form.Item
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(jobsPage.startDate)}
                shouldUpdate
              >
                {(): JSX.Element => {
                  return (
                    <DatePicker
                      showTime
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={moment(props.startDate)}
                      onChange={(momentDate): void =>
                        props.setStartDate(moment(momentDate).toDate())
                      }
                      disabled={props.job?.status !== JobStatus.Pending}
                      placeholder={t(dictionary.general.chooseDate)}
                    />
                  )
                }}
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(jobsPage.endDate)}
                shouldUpdate
              >
                {(): JSX.Element => {
                  return (
                    <DatePicker
                      showTime
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={moment(props.endDate)}
                      onChange={(momentDate): void => props.setEndDate(moment(momentDate).toDate())}
                      disabled={props.job?.status !== JobStatus.Pending}
                      placeholder={t(dictionary.general.chooseDate)}
                    />
                  )
                }}
              </Form.Item>
              <Form.Item label={t(jobsPage.duration)}>{props.jobDuration}</Form.Item>
              <Form.Item label={t(jobsPage.status)}>
                {/* <Select showArrow placeholder={t(dictionary.general.chooseFromList)}>
                  {Object.entries(JobStatus).map(([, value]) => {
                    if (value === JobStatus.Archived) return
                    return (
                      <Select.Option
                        key={value}
                        value={value}
                        disabled={
                          value === JobStatus.Pending && props.job?.status !== JobStatus.Pending
                        }
                      >
                        {value}
                      </Select.Option>
                    )
                  })}
                </Select> */}
                {props.job?.status && t(dictionary.jobStatus[props.job?.status])}
              </Form.Item>
              <Popconfirm
                placement='right'
                title={
                  <div className='edit-job__update-popconfirm'>
                    Czy na pewno chcesz rozpocząć to zadanie?
                  </div>
                }
                okText={t(dictionary.general.yes)}
                cancelText={t(dictionary.general.cancel)}
                onConfirm={props.startJob}
                disabled={!canBeStarted}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={props.patchLoading}
                  className='app-layout__form-button'
                  disabled={!canBeStarted}
                >
                  {/* TODO: this should use translations  */}
                  Rozpocznij Zadanie
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Popconfirm
              placement='right'
              title={
                <div className='edit-job__update-popconfirm'>{t(jobsPage.updateConfirmation)}</div>
              }
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onFinish}
            >
              <Button
                type='primary'
                htmlType='submit'
                loading={props.patchLoading}
                className='app-layout__form-button'
              >
                {t(dictionary.general.save)}
              </Button>
            </Popconfirm>
            <Popconfirm
              placement='left'
              title={t(jobsPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onArchive}
            >
              <Button danger loading={props.patchLoading} className='app-layout__form-button'>
                {t(dictionary.general.archive)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default JobDetailsView

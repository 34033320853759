import { useParams } from 'react-router-dom'
import { useFindQuery } from '../../../../query'

const useDeviceMap = () => {
  const { id } = useParams<{ id: string }>()

  const { data: device } = useFindQuery(
    'devices',
    id
      ? {
          _id: id,
          $limit: 1,
          getFullExternalData: true
        }
      : false
  )
  return { device: device?.data[0] }
}
export default useDeviceMap

import { useGetQuery } from '../../query'

interface WorkerDeviceUseProps {
  workerId?: string
}

const useDeviceWorker = (props: WorkerDeviceUseProps) => {
  const { data: worker, status } = useGetQuery('workers', props.workerId)

  return {
    worker: worker,
    loading: status === 'loading'
  }
}
export default useDeviceWorker

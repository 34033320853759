import { Button, Row } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import Map from '../../../../components/map'
import { GeomanMap } from '../../../../components/map/mapType'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

import './locationMap.less'

interface LocationMapViewProps {
  setMap: (map: GeomanMap) => void
  locationGeoJson?: any
  onSave: () => void
  patchLoading: boolean
  location?: Location
}

const LocationMapView: FunctionComponent<LocationMapViewProps> = (props: LocationMapViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <div className='location-map'>
      <ContentLoader loading={!props.location}>
        <Map setMap={props.setMap} geoData={props.location?.geoData} />
        <Row className='app-layout__last-row'>
          <Button
            type='primary'
            htmlType='submit'
            loading={props.patchLoading}
            className='app-layout__form-button'
            onClick={props.onSave}
          >
            {t(dictionary.general.save)}
          </Button>
        </Row>
      </ContentLoader>
    </div>
  )
}

export default LocationMapView

import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { useGetQuery } from '../../../../query'
import { assert } from '../../../../assert'

const useJobMap = () => {
  const { id } = useParams<{ id: string }>()

  const { data: job } = useGetQuery('jobs', id)

  const geoData = useMemo(() => {
    if (!job) return []
    const geoData = job.geoData?.map((geoData) => {
      return {
        ...geoData,
        text: `<a href='${generatePath(appRoutes.workerShow.routes.details.path, {
          id: geoData.text
        })}'>${geoData.text}</a>`
      }
    })
    assert(Array.isArray(geoData), 'Geo data not found')
    const mapData = job.mapData
    assert(typeof mapData !== 'undefined', 'Map data not found')
    return geoData.concat(mapData)
  }, [job])

  return {
    job,
    geoData
  }
}

export default useJobMap

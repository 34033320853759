import React, { FunctionComponent } from 'react'

import DeviceShowLayoutView from './DeviceShowLayoutView'
import useDeviceShowLayout from './useDeviceShowLayout'

interface DeviceShowLayoutProps {
  children?: React.ReactNode
}

const DeviceShowLayout: FunctionComponent<DeviceShowLayoutProps> = (
  props: DeviceShowLayoutProps
) => {
  const listLayoutState = useDeviceShowLayout()

  return <DeviceShowLayoutView {...props} {...listLayoutState} />
}

export default DeviceShowLayout

import { useGetQuery } from '../../query'

interface WorkerDeviceUseProps {
  deviceId?: string
}

const useWorkerDevice = (props: WorkerDeviceUseProps) => {
  const { data: device, status } = useGetQuery('devices', props.deviceId)

  return {
    device: device,
    loading: status === 'loading'
  }
}
export default useWorkerDevice

import { useEffect, useState } from 'react'
import { matchPath, useHistory, useParams } from 'react-router-dom'
import { LocationListener } from 'history'
import { reportShowRoutes } from '../../containers/Router/routes'
import { useGetQuery } from '../../query'

const useReportShowLayout = () => {
  const history = useHistory()

  const [menuSelectedKey, setMenuSelectedKey] = useState()

  useEffect(() => {
    const handleSelectedKey: LocationListener = (location) => {
      const menuSelectedKey = Object.entries(reportShowRoutes)
        .map(([, value]) => value)
        .find((route) =>
          matchPath(location.pathname, {
            path: route.path,
            exact: true,
            strict: false
          })
        )?.path

      setMenuSelectedKey(menuSelectedKey)
    }

    // I don't know why it was here so I'll keep it commented for now
    // history.push(reportShowRoutes.workers.path)

    // run the check on app start
    handleSelectedKey(history.location, 'PUSH')

    return history.listen(handleSelectedKey)
  }, [])

  return {
    menuSelectedKey
  }
}

export default useReportShowLayout

const pl = {
  general: {
    device: 'Urządzenie',
    worker: 'Pracownik',
    assign: 'Przypisz',
    turnOver: 'Zdaj urządzenie',
    formError: 'Pole wymagane',
    save: 'Zapisz',
    delete: 'Usuń',
    yes: 'Tak',
    cancel: 'Anuluj',
    chooseFromList: 'Wybierz z listy',
    of: 'z',
    items: 'elementów',
    addRange: 'Dodaj zakres',
    chooseDate: 'Wybierz datę',
    archive: 'Archiwizuj',
    add: 'Dodaj',
    search: 'Szukaj',
    typeSearchedText: 'Wpisz szukany tekst',
    timezone: 'Strefa czasowa',
    job: 'Zlecenie'
  },
  layouts: {
    dashboard: 'Pulpit',
    map: 'Mapa',
    jobs: 'Zlecenia',
    tasks: 'Zadania',
    locations: 'Lokalizacje',
    devices: 'Urządzenia',
    groups: 'Grupy',
    users: 'Użytkownicy',
    workers: 'Pracownicy',
    authenticationInProgress: 'Trwa autentykacja...',
    logOut: 'Wyloguj się',
    details: 'Szczegóły',
    data: 'Dane',
    history: 'Historia',
    reports: 'Raporty'
  },
  reportsPage: {
    workers: 'Pracownicy',
    managers: 'Kierownicy',
    locations: 'Lokalizacje',
    jobName: 'Zlecenie',
    actions: 'Akcje',
    generateReport: 'Generuj raport',
    reportForPeriod: 'Raport za okres:'
  },
  homePage: {
    assignDevice: 'Urządzenie zostało przypisane do pracownika.',
    turnOverError: 'Coś poszło nie tak! To urządzenie nie jest aktualnie przypisane do nikogo!',
    turnOver: 'Urządzenie zostało zdane.',
    formPlaceholder: 'Wybierz z listy',
    jobs: 'Zlecenia',
    pending: 'Oczekujące',
    started: 'Realizowane',
    finished: 'Zakończone'
  },
  loginPage: {
    passwors: 'Hasło',
    login: 'Zaloguj',
    loading: 'Ładowanie...',
    loginError: 'Błąd logowania'
  },
  mainMapPage: {
    formPlaceholder: 'Wyszukaj pracowników, urządzenia bądz zlecenia',
    filter: 'Filtr',
    noDataWarning: 'Brak danych do wyświetlenia'
  },
  logoutPage: {
    loginOut: 'Trwa wylogowywanie...'
  },
  noMatchPage: {
    return: 'Powrót do pulpitu',
    sorry: 'Przepraszamy, strona na którą próbujesz wejść nie istnieje.'
  },
  devicesPage: {
    assignedWorker: 'Przypisany pracownik',
    battery: 'Bateria',
    addDevice: 'Dodaj Urządzenie',
    deviceDetails: 'Szczegóły urządzenia',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć to urządzenie?',
    incorrectId: 'ID urządzenia niewłaściwe.',
    updated: 'Urządzenie zaktualizowane',
    lastUpdate: 'Ostatnia aktualizacja',
    efficiency: 'Efektywność pracy',
    deviceId: 'ID urządzenia',
    device: 'Urządzenie',
    assignedDate: 'Data przypisania',
    returnedDate: 'Data zdania'
  },
  usersPage: {
    addUser: 'Dodaj Użytkownika',
    createDate: 'Data utworzenia',
    userDetails: 'Szczegóły użytkownika',
    password: 'Hasło',
    incorrectId: 'ID użytkownika niewłaściwe.',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć tego użytkownika?',
    updated: 'Użytkownik zaktualizowane',
    createUser: 'Stwórz Użytkownika'
  },
  workersPage: {
    addWorker: 'Dodaj Pracownika',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    groups: 'Grupy',
    phoneNumber: 'Telefon kontaktowy',
    score: 'Ocena',
    createWorker: 'Stwórz Pracownika',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć tego pracownika?',
    incorrectId: 'ID pracownika niewłaściwe.',
    updated: 'Pracownik zaktualizowane',
    updateDate: 'Ostatnia data modyfikacji',
    createDate: 'Data utworzenia',
    assignment: 'Przypisanie',
    basicData: 'Dane Podstawowe',
    calendar: 'Kalendarz Pracy',
    identifier: 'Number Identyfikacyjny',
    startDate: 'Data rozpoczęcia pracy',
    endDate: 'Data zakończenia pracy',
    active: 'Aktywny/a',
    inActive: 'Nie aktywny/a',
    manager: 'Kierownik',
    current: 'Aktualnie',
    deviceId: 'ID urządzenia',
    status: 'Status',
    missing: 'Brak',
    efficiencyToday: 'Efektywność dzisiaj',
    efficiencyYesterday: 'Efektywność wczoraj',
    efficiencyWeek: 'Efektywność tygodnia',
    efficiencyMonth: 'Efektywność miesiąca',
    assignedJobs: 'Przypisane zlecenia'
  },
  locationsPage: {
    addLocation: 'Dodaj lokalizacje',
    name: 'Nazwa',
    type: 'Typ',
    incorrectId: 'ID lokalizacji niewłaściwe.',
    updated: 'Lokalizacja zaktualizowane',
    recordsAmount: 'Ilość rekordów',
    createLocation: 'Stwórz Lokalizacje',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć tą lokalizacje?',
    updateDate: 'Ostatnia data modyfikacji',
    createDate: 'Data utworzenia',
    active: 'Aktywna'
  },
  tasksPage: {
    addTask: 'Dodaj Zadanie',
    name: 'Nazwa',
    client: 'Klient',
    calendar: 'Kalendarz',
    createTask: 'Stwórz Zadanie',
    taskInformation: 'Informaje o zadaniu',
    description: 'Opis',
    contractNumber: 'Numer Umowy',
    coordinator: 'Koordynator zadania',
    contactPerson: 'Osoba kontaktowa',
    taskRepeat: 'Powtarzanie zadania',
    taskRepeatInfo: 'Każdy przedział będzie osobnym zleceniem',
    incorrectId: 'ID zadania niewłaściwe.',
    updated: 'Zadanie zaktualizowane',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć to zadanie?',
    locationAdded: 'Dodano lokalizację',
    locationRemoved: 'Usunięto lokalizację',
    workersToAssign: 'Pracownicy do przypisania',
    addGroup: 'Dodaj grupę',
    numberOfWorkers: 'Liczba pracowników',
    addGroupError: 'Grupy nie mogą się powtarzać!',
    missingGroupValueError: 'Brakujące pola w formularzu (grupy)',
    dateRange: 'Przedział czasowy',
    startDate: 'Data rozpoczęcia',
    endDate: 'Data zakończenia'
  },
  groupsPage: {
    addGroup: 'Dodaj grupę',
    name: 'Nazwa',
    groupDetails: 'Szczegóły grupy',
    incorrectId: 'ID grupy niewłaściwe.',
    updated: 'Grupa zaktualizowane',
    createGroup: 'Stwórz Grupę',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć tą grupę?',
    createDate: 'Data utworzenia',
    addToGroup: 'Dodaj do grupy',
    numberOfWorkers: 'Przypisani Pracownicy',
    workerAdded: 'Dodano pracownika do grupy',
    workerRemoved: 'Usunięto pracownika z grupy'
  },
  jobsPage: {
    addJob: 'Dodaj Zlecenie',
    name: 'Nazwa',
    status: 'Status',
    jobTime: 'Czas zadania',
    startDate: 'Czas rozpoczęcia',
    endDate: 'Czas zakończenia',
    duration: 'Czas trwania',
    task: 'Zadanie',
    incorrectId: 'ID zadania niewłaściwe.',
    deleteConfirmation: 'Jesteś pewien/na że chcesz usunąć to zlecenie?',
    days: 'dni',
    hours: 'godzin',
    minutes: 'minut',
    client: 'Klient',
    taskInformation: 'Informaje o zadaniu',
    description: 'Opis',
    contractNumber: 'Numer Umowy',
    coordinator: 'Koordynator zadania',
    contactPerson: 'Osoba kontaktowa',
    updated: 'Zadanie zaktualizowane',
    workers: 'Pracownicy',
    group: 'Grupa',
    assignedWorkers: 'Przypisani pracownicy',
    requiredWorkers: 'Wymagani pracownicy',
    chooseWorker: 'Wybierz pracownika',
    chooseGroup: 'Wybierz grupę',
    workerAdded: 'Dodano pracownika do zadania',
    workerRemoved: 'Usunięto pracownika z zadania',
    jobDone: 'Zakończone',
    jobInProgress: 'W toku',
    jobPending: 'Nieaktywne',
    updateConfirmation:
      'Aktualizacja zlecenia spowoduje usunięcie przypisanych pracowników oraz ręczne ich przypisanie. Jesteś pewny/na że chcesz zmodyfikować to zlecenie?'
  },
  jobStatus: {
    PENDING: 'Oczekujące',
    STARTED: 'Rozpoczęte',
    FINISHED: 'Ukończone',
    ARCHIVED: 'Zarchiwizowane',
    INVALID: 'Niewłaściwe'
  },
  calendar: {
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',
    to: 'do',
    errorMessage:
      'Czas zakończenia każdego przedziału nie może być wcześniejszy niż rozpoczęcia tego przedziału.',
    missingFieldError: 'Wszystkie pola przedziału kalendarza muszą zostać uzupełnione.',
    mondayShort: 'P',
    tuesdayShort: 'W',
    wednesdayShort: 'Ś',
    thursdayShort: 'C',
    fridayShort: 'P',
    saturdayShort: 'S',
    sundayShort: 'N',
    addRange: 'Dodaj przedział',
    start: 'Start',
    end: 'Zakończenie'
  }
}

export default pl

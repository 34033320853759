import React, { FunctionComponent, useEffect } from 'react'
import L, { MapOptions } from 'leaflet'
import 'leaflet-arrowheads'

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import './map.less'

import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { coordinates, GeoData, GeomanMap, LatLng } from './mapType'
import { shapeType } from './geomanShape.enum'

interface MapCenter {
  north: number | undefined
  south: number | undefined
  west: number | undefined
  east: number | undefined
}

const defaultMapCenter = {
  center: {
    // Warsaw center
    lat: 52.221113,
    lng: 21.012174
  },
  zoom: 13
}

const getMapCenter = (geoData: GeoData[] | undefined): { center: LatLng; zoom: number } => {
  if (!geoData?.length) return defaultMapCenter

  const mapCenter: MapCenter = {
    north: undefined,
    south: undefined,
    west: undefined,
    east: undefined
  }

  geoData?.map((data) => {
    switch (data.geometry.type) {
      case shapeType.Point:
        const coordinatesPoint = data.geometry.coordinates as coordinates

        if (mapCenter.east === undefined || coordinatesPoint[0] > mapCenter.east)
          mapCenter.east = coordinatesPoint[0]
        if (mapCenter.west === undefined || coordinatesPoint[0] < mapCenter.west)
          mapCenter.west = coordinatesPoint[0]
        if (mapCenter.north === undefined || coordinatesPoint[1] > mapCenter.north)
          mapCenter.north = coordinatesPoint[1]
        if (mapCenter.south === undefined || coordinatesPoint[1] < mapCenter.south)
          mapCenter.south = coordinatesPoint[1]
        break

      case shapeType.Polygon:
        const coordinatesPolygon = data.geometry.coordinates as coordinates[][]

        coordinatesPolygon.map((shapeCoordinates) => {
          shapeCoordinates.map((shapePointCoordinates) => {
            if (mapCenter.east === undefined || shapePointCoordinates[0] > mapCenter.east)
              mapCenter.east = shapePointCoordinates[0]
            if (mapCenter.west === undefined || shapePointCoordinates[0] < mapCenter.west)
              mapCenter.west = shapePointCoordinates[0]
            if (mapCenter.north === undefined || shapePointCoordinates[1] > mapCenter.north)
              mapCenter.north = shapePointCoordinates[1]
            if (mapCenter.south === undefined || shapePointCoordinates[1] < mapCenter.south)
              mapCenter.south = shapePointCoordinates[1]
          })
        })
        break

      case shapeType.LineString:
        const coordinatesLine = data.geometry.coordinates as coordinates[]

        coordinatesLine.map((coordinates) => {
          if (mapCenter.east === undefined || coordinates[0] > mapCenter.east)
            mapCenter.east = coordinates[0]
          if (mapCenter.west === undefined || coordinates[0] < mapCenter.west)
            mapCenter.west = coordinates[0]
          if (mapCenter.north === undefined || coordinates[1] > mapCenter.north)
            mapCenter.north = coordinates[1]
          if (mapCenter.south === undefined || coordinates[1] < mapCenter.south)
            mapCenter.south = coordinates[1]
        })
        break
    }
  })

  if (
    mapCenter.east === undefined ||
    mapCenter.west === undefined ||
    mapCenter.north === undefined ||
    mapCenter.south === undefined
  ) {
    return defaultMapCenter
  }

  let latitudeDifference = Math.abs(mapCenter.east) - Math.abs(mapCenter.west)
  let longitudeDifference = Math.abs(mapCenter.north) - Math.abs(mapCenter.south)

  let zoom = 1

  if (latitudeDifference === 0 && longitudeDifference === 0) {
    zoom = 13
  } else {
    while (latitudeDifference < 180 && longitudeDifference < 180) {
      latitudeDifference *= 2
      longitudeDifference *= 2
      zoom++
    }
  }

  return {
    center: {
      lat: (mapCenter.north + mapCenter.south) / 2,
      lng: (mapCenter.west + mapCenter.east) / 2
    },
    zoom: zoom
  }
}

interface MapWithGeomanProps extends MapOptions {
  setMap?: (map: GeomanMap) => void
  geoData?: GeoData[]
  scores?: number[]
}

const MapWithGeoman: FunctionComponent<MapWithGeomanProps> = (props: MapWithGeomanProps) => {
  const map: GeomanMap = useMap() as GeomanMap

  useEffect((): (() => void) | undefined => {
    if (!map) return

    if (props.setMap) {
      props.setMap(map)

      map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawCircle: false
      })
    }

    if (!props.geoData || !props.geoData.length) return

    const layers: L.Layer[] = props.geoData.map((geoData, index) => {
      let layer: any

      const color = geoData.color ? geoData.color : 'rgb(51, 136, 255)'

      if (geoData.geometry.type === shapeType.LineString) {
        const latLngs = (geoData.geometry.coordinates as coordinates[]).map(
          (coordinates) => new L.LatLng(coordinates[1], coordinates[0])
        )
        // const points = latLngs.map((latLng) => map.latLngToLayerPoint(latLng))
        // const simplifiedPoints = L.LineUtil.simplify(points, 1)
        // const simplifiedLatLngs = simplifiedPoints.map((point) => map.layerPointToLatLng(point))
        // console.log('before simplify', latLngs.length, 'after simplify', simplifiedLatLngs.length)
        layer = (L.polyline(latLngs, { color: color, smoothFactor: 2.0 }) as any)
          .arrowheads({ frequency: '120px', size: '12px' })
          .addTo(map)

        geoData.text && layer.bindPopup(geoData.text)
      } else if (geoData.geometry.type === shapeType.Point) {
        if (geoData.activeTask !== undefined) {
          const markerColorUrl = geoData.activeTask
            ? '/marker-icon-2x-green.png'
            : '/marker-icon-2x-grey.png'

          const greenIcon = new L.Icon({
            iconUrl: process.env.PUBLIC_URL + markerColorUrl,
            shadowUrl: process.env.PUBLIC_URL + '/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          })

          layer = L.marker(
            [
              (geoData.geometry.coordinates as coordinates)[1],
              (geoData.geometry.coordinates as coordinates)[0]
            ],
            {
              icon: greenIcon,
              autoPan: true
            }
          )
          map.addLayer(layer)

          layer.bindPopup(geoData.text)
          ;(layer as L.Marker<any>)
            .bindTooltip(geoData.score?.toFixed(0) || '', {
              permanent: true,
              interactive: false,
              sticky: true,
              direction: 'center',
              className: '--map-score-tooltip',
              offset: [2, -16]
            })
            .openTooltip()
        } else {
          layer = L.geoJSON(geoData).addTo(map)
        }
      } else {
        layer = L.geoJSON(geoData).addTo(map)
      }

      if (geoData.geometry.type !== shapeType.Point) {
        layer.on('mouseover', function () {
          layer.setStyle({
            color: 'blue'
          })
        })
        layer.on('mouseout', function () {
          layer.setStyle({
            color: color
          })
        })
      }

      return layer
    })
    const group = L.featureGroup(layers)
    map.fitBounds(group.getBounds())

    return (): void => layers.forEach((layer) => map.removeLayer(layer))
  }, [map, props.geoData])

  return (
    <map>
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
    </map>
  )
}

const Map: FunctionComponent<MapWithGeomanProps> = (props: MapWithGeomanProps) => {
  const { center, zoom } = getMapCenter(props.geoData)

  return (
    <MapContainer center={center} zoom={zoom} scrollWheelZoom={true} className='map'>
      <MapWithGeoman {...props} />
    </MapContainer>
  )
}

export default Map

import React, { FunctionComponent } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { Tag } from 'antd'
import Map from '../../../../components/map'
import moment from 'moment'

import './workerMap.less'
import { GeoData } from '../../../../components/map/mapType'
import { TimerangeSelectorBar } from '../../../../components/timerangeSelectorBar'
import { JobSelector } from './JobSelector'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'

type RangeValue = [moment.Moment | null, moment.Moment | null] | null

export interface WorkerMapViewProps {
  worker?: Worker
  geoData: GeoData[]
  rangeValue: RangeValue
  setRangeValue: (range: RangeValue) => void
  loading: boolean
  jobs: Job[]
  selectedJobIds: string[]
  setSelectedJobIds: (jobIds: string[]) => void
}

const WorkerMapView: FunctionComponent<WorkerMapViewProps> = ({
  geoData,
  rangeValue,
  setRangeValue,
  loading,
  jobs,
  selectedJobIds,
  setSelectedJobIds
}: WorkerMapViewProps) => {
  return (
    <div className='worker-map'>
      <TimerangeSelectorBar
        range={rangeValue}
        setRange={setRangeValue}
        title='Wyświetlanie trasy'
      />
      <ContentLoader loading={loading}>
        <JobSelector
          jobs={jobs}
          selectedJobIds={selectedJobIds}
          setSelectedJobIds={setSelectedJobIds}
        />
        <Map geoData={geoData} />
      </ContentLoader>
    </div>
  )
}

export default WorkerMapView

import React, { FunctionComponent, useEffect, useContext } from 'react'
import ContentLoader from '../../components/ContentLoader'
import Map from '../../components/map'
import { GeoData } from '../../components/map/mapType'
import { message } from 'antd'
import { LocalizationContext } from '../../providers/LocalizationProvider'

export interface MainMapViewProps {
  geoData: GeoData[]
  loading: boolean
}

const MainMapView: FunctionComponent<MainMapViewProps> = (props: MainMapViewProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const { t, dictionary } = useContext(LocalizationContext)

  useEffect(() => {
    if (props.loading) return
    if (props.geoData.length > 0) return
    // on the initial load, loading goes false before geoData is populated
    // so we need debounce to wait for the data to be populated
    const timeout = setTimeout(() => {
      messageApi.warning(t(dictionary.mainMapPage.noDataWarning))
    }, 100)
    return (): void => clearTimeout(timeout)
  }, [props.geoData, props.loading])

  return (
    <ContentLoader loading={props.loading}>
      {contextHolder}
      <Map geoData={props.geoData} />
    </ContentLoader>
  )
}

export default MainMapView

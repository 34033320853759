type Viewport = {
  x: number
  y: number
  w: number
  h: number
}

export const saveToPNG = (elementId: string, filename: string, view: Viewport): void => {
  const svg = document.getElementById(elementId)
  if (!svg) return
  const svgData = new XMLSerializer().serializeToString(svg)
  const canvas = document.createElement('canvas')
  canvas.width = view.w
  canvas.height = view.h
  const ctx = canvas.getContext('2d')
  if (!ctx) return
  const img = new Image()
  img.width = view.w
  img.height = view.h

  img.onload = (): void => {
    ctx.drawImage(img, -view.x, -view.y)
    const a = document.createElement('a')
    a.download = filename
    a.href = canvas.toDataURL('image/png')
    a.click()
  }

  img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)))
}

import { Form, message } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { taskShowRoutes } from '../../../containers/Router/routes'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { useCreateMutation, useFindQuery } from '../../../query'

const useTasksCreate = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  const [form] = Form.useForm()

  const history = useHistory()

  const [createTask, { status: createStatus }] = useCreateMutation('tasks')

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const [groupSearch, setGroupSearch] = useState<string>('')

  const { data: groupsRes } = useFindQuery('groups', {
    $limit: 10,
    name: { $regex: groupSearch, $options: 'i' }
  })

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()

      if (values.workersToAssign && values.workersToAssign.length) {
        const groupIds: string[] = []

        for (let i = 0; i < values.workersToAssign.length; i++) {
          if (!values.workersToAssign[i].groupId || !values.workersToAssign[i].numberOfWorkers) {
            message.error(t(tasksPage.missingGroupValueError))
            return
          }

          if (groupIds.indexOf(values.workersToAssign[i].groupId) !== -1) {
            message.error(t(tasksPage.addGroupError))
            return
          }

          groupIds.push(values.workersToAssign[i].groupId)
        }
      }

      const task = await createTask({
        ...values
      })

      history.replace(
        generatePath(taskShowRoutes.details.path, {
          id: task._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  return {
    form,
    onFinish,
    workers: workersRes?.data || [],
    createLoading: createStatus === 'loading',
    setGroupSearch,
    setWorkerSearch,
    groups: groupsRes?.data
  }
}
export default useTasksCreate

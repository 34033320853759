import qs from 'qs'
import useTable from '../../components/useTable'
import { useFindQuery } from '../../query'
import { ReactText, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes, jobShowRoutes } from '../../containers/Router/routes'
import { Job } from '../../providers/ClientProvider/client/services/jobs/types'

const useJobs = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Job>()

  const history = useHistory()

  const { data: jobsRes, status } = useFindQuery('jobs', {
    $sort: { start: '-1' },
    ...query
  })

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(jobShowRoutes.details.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewLocation = useCallback((): void => {
    history.push(appRoutes.jobCreate.path)
  }, [])

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  return {
    jobs: jobsRes?.data,
    loading: status === 'loading',
    total: jobsRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewLocation,
    handleFilter
  }
}
export default useJobs

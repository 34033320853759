import { DownCircleFilled, UpCircleFilled, MinusCircleFilled } from '@ant-design/icons'
import { Statistic } from 'antd'
import React, { FunctionComponent } from 'react'

import './efficiency.less'

interface EfficiencyViewProps {
  title: string
  raise?: boolean
  value?: number
}

const EfficiencyView: FunctionComponent<EfficiencyViewProps> = (props: EfficiencyViewProps) => {
  if (typeof props.value !== 'number') {
    return (
      <Statistic
        className='efficiency'
        title={props.title}
        valueStyle={{ color: '#888888' }}
        value={'Brak'} // TODO: translate
        prefix={<MinusCircleFilled />}
      />
    )
  }

  // TODO: figure out what to do with this
  return props.raise ? (
    <Statistic
      className='efficiency'
      title={props.title}
      valueStyle={{ color: '#70b603' }}
      value={Math.round(props.value)}
      prefix={<UpCircleFilled />}
    />
  ) : (
    <Statistic
      className='efficiency'
      title={props.title}
      valueStyle={{ color: '#d9001b' }}
      value={Math.round(props.value)}
      prefix={<DownCircleFilled />}
    />
  )
}

export default EfficiencyView

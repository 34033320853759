import { useMemo, useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { GeoData } from '../../components/map/mapType'
import { appRoutes } from '../../containers/Router/routes'
import { useFindQuery } from '../../query'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import moment from 'moment'
import { MainMapViewProps } from './MainMapView'
import { FormProps } from './FormView'

interface MapFilter {
  id: string
  type: 'worker' | 'job' | 'device'
}

const useMainMap = (): { mapProps: MainMapViewProps; formProps: FormProps } => {
  const [combinedGeoData, setCombinedGeoData] = useState<GeoData[]>([])
  const [filter, setFilter] = useState<MapFilter | null>(null)
  const now = useMemo(() => Date.now(), [])

  const { data: workerDevices, status: workerDeviceStatus } = useFindQuery('workerDevices', {
    $limit: 10000,
    returnedDate: null
  })

  const activeWorkersIds = workerDevices?.data.map((workerDevice) => workerDevice.workerId)
  const deviceIds = workerDevices?.data.map((workerDevice) => workerDevice.deviceId)

  const { data: devices, status: deviceStatus } = useFindQuery(
    'devices',
    workerDevices && workerDevices.total
      ? {
          $limit: 10000,
          _id: { $in: deviceIds },
          getFullExternalData: true
        }
      : false
  )

  const { data: workers, status: workersStatus } = useFindQuery(
    'workers',
    workerDevices && workerDevices.total
      ? {
          $limit: 10000,
          _id: { $in: activeWorkersIds },
          getDeviceId: true,
          getLatestScore: true
        }
      : false
  )

  const { data: jobWorkers, status: jobWorkersStatus } = useFindQuery(
    'jobWorkers',
    workerDevices && workerDevices.total
      ? {
          $limit: 10000,
          workerId: { $in: activeWorkersIds },
          end: { $gte: now },
          start: { $lte: now }
        }
      : false
  )

  // to display jobs happening now
  const { data: recentJobs } = useFindQuery('jobs', {
    $sort: { start: '-1' },
    $limit: 10000,
    start: { $lte: now },
    end: { $gte: now }
  })

  const buildGeoLocation = (device: Device, worker: Worker): GeoData | undefined => {
    if (!device.lastLocation || !jobWorkers || !worker) return
    const isFresh =
      device.lastUpdate && moment(device.lastUpdate).isAfter(moment().subtract(1, 'hours'))
    return {
      ...device.lastLocation,
      score: isFresh ? worker.score : undefined,
      activeTask: !!jobWorkers.data.find((jobWorker) => jobWorker.workerId === worker?._id),
      text: `<a href='${generatePath(appRoutes.workerShow.routes.details.path, {
        id: worker?._id
      })}'>${worker?.firstName} ${worker?.lastName}</a>`
    }
  }

  const filterByDevice = (filter: MapFilter): void => {
    const geoData: GeoData[] = []
    const device = devices?.data.find((device) => device._id === filter.id)
    const worker = workers?.data.find((worker) => worker.deviceId === device?._id)

    if (device && worker) {
      const geoLocation = buildGeoLocation(device, worker)
      if (geoLocation) geoData.push(geoLocation)
    }

    setCombinedGeoData(geoData)
  }

  const filterByJob = (filter: MapFilter): void => {
    const geoData: GeoData[] = []
    const job = recentJobs?.data.find((job) => job._id === filter.id)

    if (!job || !job._id) {
      setCombinedGeoData(geoData)
      return
    }

    const workersIds = jobWorkers?.data
      ?.filter((jobWorker) => jobWorker.jobId == job._id)
      .map((jobWorker) => jobWorker.workerId)

    workersIds?.forEach((id) => {
      const worker = workers?.data.find((worker) => worker._id === id)
      const device = devices?.data.find((device) => device._id === worker?.deviceId)

      if (!device || !worker) return

      const geoLocation = buildGeoLocation(device, worker)

      if (!geoLocation) return

      geoData.push(geoLocation)
    })
    setCombinedGeoData(geoData)
  }

  const filterByWorker = (filter: MapFilter): void => {
    const geoData: GeoData[] = []
    const worker = workers?.data.find((worker) => worker._id === filter.id)
    const device = devices?.data.find((dvc) => dvc._id === worker?.deviceId)

    if (device && worker) {
      const geoLocation = buildGeoLocation(device, worker)
      if (geoLocation) geoData.push(geoLocation)
    }

    setCombinedGeoData(geoData)
  }

  const prepareDataWithoutFilters = (): void => {
    const geoData: GeoData[] = []
    devices?.data.forEach((device) => {
      const worker = workers?.data.find((worker) => worker.deviceId === device._id)

      if (!worker) return

      const geoLocation = buildGeoLocation(device, worker)

      if (!geoLocation) return

      geoData.push(geoLocation)
    })

    setCombinedGeoData(geoData)
  }

  const prepareData = (filter: MapFilter | null): void => {
    if (!filter || !filter.type || !workers) {
      prepareDataWithoutFilters()
      return
    }

    switch (filter.type) {
      case 'device':
        filterByDevice(filter)
        break
      case 'job':
        filterByJob(filter)
        break
      case 'worker':
        filterByWorker(filter)
        break
    }
  }

  useEffect(() => {
    if (devices) {
      prepareData(filter)
    }
  }, [devices, workers, jobWorkers, filter])

  const handleFormSelect = (value: string): void => {
    if (value) return setFilter(JSON.parse(value))
    else return setFilter(null)
  }

  const loading =
    workerDeviceStatus === 'loading' ||
    deviceStatus === 'loading' ||
    workersStatus === 'loading' ||
    jobWorkersStatus === 'loading'

  return {
    formProps: {
      handleSelect: handleFormSelect,
      allWorkers: workers?.data || [],
      allSuitableDevices: devices?.data || [],
      recentJobs: recentJobs?.data || []
    },
    mapProps: {
      geoData: combinedGeoData,
      loading
    }
  }
}

export default useMainMap

import React from 'react'

import DeviceDetailsView from './DeviceDetailsView'
import useDeviceDetails from './useDeviceDetails'

const DeviceDetails = (): JSX.Element => {
  const state = useDeviceDetails()

  return <DeviceDetailsView {...state} />
}

export default DeviceDetails

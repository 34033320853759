import React from 'react'
import WorkerTimelineView from './WorkerTimelineView'
import useWorkerTimeline from './useWorkerTimeline'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'

interface WorkerTimelineProps {
  worker?: Worker
}

const WorkerTimeline = (props: WorkerTimelineProps) => {
  const state = useWorkerTimeline({ ...props })

  return <WorkerTimelineView {...state} {...props} />
}

export default WorkerTimeline

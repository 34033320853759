/* eslint-disable react/display-name */
import React, { FunctionComponent, useContext } from 'react'
import { Table } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './deviceHistory.less'

import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import DeviceWorker from '../../../../components/deviceWorker'
import moment from 'moment'
import { WorkerDevice } from '../../../../providers/ClientProvider/client/services/workerDevices/types'
import { onTableChange } from '../../../../components/useTable'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  devicesPage: any
  t: any
}

export const Columns = (props: ColumnProps): ColumnType<WorkerDevice>[] => {
  return [
    {
      title: props.t(props.devicesPage.assignedWorker),
      render: (value: string, record: WorkerDevice): JSX.Element | undefined => (
        <DeviceWorker workerId={record.workerId} />
      )
    },
    {
      title: props.t(props.devicesPage.assignedDate),
      dataIndex: 'assignedDate',
      sorter: true,
      render: (value: Date) => {
        return value ? <>{moment(value).format('HH:mm D-M-YYYY')}</> : <>N/A</>
      }
    },
    {
      title: props.t(props.devicesPage.returnedDate),
      dataIndex: 'returnedDate',
      sorter: true,
      render: (value: Date) => {
        return value ? <>{moment(value).format('HH:mm D-M-YYYY')}</> : <>N/A</>
      }
    }
  ]
}

interface DeviceHistoryViewProps {
  workerDevices?: WorkerDevice[]
  form: FormInstance
  loading: boolean
  total: number
  onTableChange: onTableChange<WorkerDevice>
  currentPage: number
  currentSort: { [key: string]: SortOrder }
}

const DeviceHistoryView: FunctionComponent<DeviceHistoryViewProps> = (
  props: DeviceHistoryViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  return (
    <div className='edit-device'>
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          t: t,
          devicesPage: devicesPage
        })}
        loading={props.loading}
        dataSource={props.workerDevices}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
      />
    </div>
  )
}

export default DeviceHistoryView

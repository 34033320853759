/* eslint-disable react/display-name */
import React, { FunctionComponent, HTMLAttributes, ReactText, useContext } from 'react'
import { Table } from 'antd'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import FilterTextSearch from '../../components/FilterTextSearch'
import FilterTags from '../../components/filterTags'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Task } from '../../providers/ClientProvider/client/services/tasks/types'
import CalendarDays from '../../components/calendarDays'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  tasksPage: any
  t: any
}

export const Columns = (props: ColumnProps): ColumnType<Task>[] => {
  return [
    {
      title: props.t(props.tasksPage.name),
      dataIndex: 'name',
      sorter: true,
      sortOrder: props?.currentSort['name'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'name'
        })
    },
    {
      title: props.t(props.tasksPage.client),
      dataIndex: 'client',
      sorter: true,
      sortOrder: props?.currentSort['client'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'client'
        })
    },
    {
      title: props.t(props.tasksPage.calendar),
      // sorter: true,
      responsive: ['md'],
      render: (record: Task): JSX.Element => <CalendarDays calendar={record.calendar} />
    }
  ]
}

interface TasksViewProps {
  tasks?: Task[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Task>
  handleOnRowClick: (id: string) => void
  onCreateNewLocation: () => void
  handleFilter: (value: ReactText, dataIndex: string) => void
}

const TasksView: FunctionComponent<TasksViewProps> = (props: TasksViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  return (
    <ListLayout
      title={t(dictionary.layouts.tasks)}
      buttonAction={props.onCreateNewLocation}
      buttonText={t(tasksPage.addTask)}
    >
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          tasksPage
        })}
        loading={props.loading}
        dataSource={props.tasks}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default TasksView

import * as d3 from 'd3'
import React, { useEffect, useMemo, useRef } from 'react'
import { multiFormat } from './multiFormat'

type XAxisProps = {
  xScale: d3.ScaleTime<number, number>
  zoom: [Date, Date]
  height: number
}

const XAxis = ({ xScale, zoom, height }: XAxisProps): JSX.Element => {
  const ref = useRef<SVGSVGElement>(null)
  const xAxis = useMemo(
    () =>
      d3
        .axisBottom(xScale)
        .tickFormat(multiFormat as any)
        .ticks(5) as d3.Axis<Date>,
    [xScale]
  )
  useEffect(() => {
    d3.select(ref.current)
      .call(xAxis as any)
      .selectAll('.tick')
      .selectAll('line.grid')
      .data([null])
      .join('line')
      .classed('grid', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', -height)
      .attr('stroke', 'currentColor')
      .attr('opacity', 0.1)
  }, [xAxis, ref, zoom])
  return <g className='x-axis' ref={ref}></g>
}

export default XAxis

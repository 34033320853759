import { message } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { queryCache } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import useTable from '../../../../components/useTable'
import { workerShowRoutes } from '../../../../containers/Router/routes'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useCreateMutation, useDeleteMutation, useFindQuery, useGetQuery } from '../../../../query'

const useGroupWorkers = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const groupsPage = dictionary.groupsPage

  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { query, onTableChange, currentSort, currentPage } = useTable<Worker>()

  const { data: group } = useGetQuery('groups', id)

  const { data: workersRes, status: finWorkersStatus } = useFindQuery(
    'workers',
    group?.assignedWorkersIds?.length
      ? {
          ...query,
          _id: { $in: group?.assignedWorkersIds }
        }
      : false
  )

  const { data: workerGroupsRes, status: finWorkerGroupsStatus } = useFindQuery(
    'workerGroups',
    workersRes?.total
      ? {
          groupId: id,
          workerId: { $in: workersRes.data }
        }
      : false
  )

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: availableWorkersRes, status: findAvailableWorkersStatus } = useFindQuery(
    'workers',
    {
      $limit: 10,
      _id: {
        $nin: group?.assignedWorkersIds || []
      },
      $or: [
        { firstName: { $regex: workerSearch, $options: 'i' } },
        { lastName: { $regex: workerSearch, $options: 'i' } }
      ]
    }
  )

  const [createWorkerGroup, { status: createWorkerGroupStatus }] = useCreateMutation('workerGroups')

  const [deleteWorkerGroups, { status: deleteWorkerGroupsStatus }] =
    useDeleteMutation('workerGroups')

  const [selectedWorker, setSelectedWorker] = useState<string>('')

  const onAddWorker = useCallback(async () => {
    try {
      if (!selectedWorker) return

      await createWorkerGroup({
        workerId: selectedWorker,
        groupId: id
      })

      setWorkerSearch('')
      setSelectedWorker('')

      message.success(t(groupsPage.workerAdded))

      await queryCache.refetchQueries(id)
      await queryCache.refetchQueries('workers')
    } catch (e) {
      console.log(e)
    }
  }, [selectedWorker])

  const onRemoveWorker = useCallback(
    async (workerId: string) => {
      try {
        if (!workerGroupsRes?.total) return

        const workerToDelete = workerGroupsRes?.data.find(
          (workerGroup) => workerGroup.workerId === workerId
        )

        if (!workerToDelete) return

        await deleteWorkerGroups(workerToDelete._id)

        message.success(t(groupsPage.workerRemoved))

        await queryCache.refetchQueries(id)
        await queryCache.refetchQueries('workers')
        await queryCache.refetchQueries('workerGroups')
      } catch (e) {
        console.log(e)
      }
    },
    [workerGroupsRes]
  )

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(workerShowRoutes.data.path, {
        id: id
      })
    )
  }, [])

  return {
    total: group?.assignedWorkersIds?.length || 0,
    currentPage,
    currentSort,
    onTableChange,
    workers: workersRes?.data,
    workersLoading: finWorkersStatus === 'loading',
    availableWorkers: availableWorkersRes?.data,
    availableWorkersLoading: findAvailableWorkersStatus === 'loading',
    setWorkerSearch,
    setSelectedWorker,
    handleOnRowClick,
    onRemoveWorker,
    removeWorkerLoading: deleteWorkerGroupsStatus === 'loading',
    onAddWorker,
    addWorkerLoading: createWorkerGroupStatus === 'loading'
  }
}
export default useGroupWorkers

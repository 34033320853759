import { Menu } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { deviceShowRoutes } from '../../containers/Router/routes'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './deviceShowLayout.less'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface DeviceShowLayoutViewProps {
  children?: React.ReactNode
  device?: Device
  menuSelectedKey?: string
  id: string
}

const DeviceShowLayoutView: FunctionComponent<DeviceShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <ContentLoader loading={!props.device}>
      <SubpageLayout title={props.device ? props.device?.imei : ''} page={t(layouts.devices)}>
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={deviceShowRoutes.details.path}>
            <Link to={generatePath(deviceShowRoutes.details.path, { id: props.id })} replace>
              {t(layouts.details)}
            </Link>
          </Menu.Item>
          <Menu.Item key={deviceShowRoutes.data.path}>
            <Link to={generatePath(deviceShowRoutes.data.path, { id: props.id })} replace>
              {t(layouts.data)}
            </Link>
          </Menu.Item>
          <Menu.Item key={deviceShowRoutes.map.path}>
            <Link to={generatePath(deviceShowRoutes.map.path, { id: props.id })} replace>
              {t(layouts.map)}
            </Link>
          </Menu.Item>
          <Menu.Item key={deviceShowRoutes.history.path}>
            <Link to={generatePath(deviceShowRoutes.history.path, { id: props.id })} replace>
              {t(layouts.history)}
            </Link>
          </Menu.Item>
        </Menu>
        <div className='device-show-layout'>{props.children}</div>
      </SubpageLayout>
    </ContentLoader>
  )
}

export default DeviceShowLayoutView

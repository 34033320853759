import { Form, message } from 'antd'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { queryCache } from 'react-query'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import {
  useCreateMutation,
  useDeleteMutation,
  useFindQuery,
  useGetQuery,
  usePatchMutation
} from '../../../../query'
import moment from 'moment'

const useWorkerData = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const workersPage = dictionary.workersPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: worker } = useGetQuery('workers', id)

  const [patchWorker, { status: patchStatus }] = usePatchMutation('workers')

  const [deleteWorker, { status: deleteStatus }] = useDeleteMutation('workers')

  const { data: workerDevicesRes } = useFindQuery('workerDevices', {
    workerId: id,
    returnedDate: null
  })

  const { data: device, status: deviceStatus } = useGetQuery(
    'devices',
    workerDevicesRes ? workerDevicesRes.data[0]?.deviceId : null
  )

  const [deviceSearch, setDeviceSearch] = useState<string>('')

  const { data: devicesRes } = useFindQuery('devices', {
    $limit: 10,
    imei: { $regex: deviceSearch, $options: 'i' },
    getWorkerId: true
  })

  const [patchWorkerDevice] = usePatchMutation('workerDevices')

  const [createWorkerDevice] = useCreateMutation('workerDevices')

  const [createWorkerGroup, { status: createWorkerGroupStatus }] = useCreateMutation('workerGroups')

  const [deleteWorkerGroup, { status: deleteWorkerDeviceStatus }] =
    useDeleteMutation('workerGroups')

  const { data: workerGroupsRes } = useFindQuery('workerGroups', {
    $limit: 1000,
    workerId: id
  })

  const [groupSearch, setGroupSearch] = useState<string>('')

  const { data: groupsRes } = useFindQuery('groups', {
    $limit: 5,
    name: { $regex: groupSearch, $options: 'i' }
  })

  const { data: thisWorkerGroupsRes, status: findThisWorkerGroupsStatus } = useFindQuery(
    'groups',
    workerGroupsRes?.data.length
      ? {
          $limit: 1000,
          _id: {
            $in: workerGroupsRes?.data.map((workerGroup) => workerGroup.groupId)
          }
        }
      : false
  )

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useMemo(() => {
    if (!worker) return
    setStartDate(worker.startDate)
    setEndDate(worker.endDate)
  }, [worker])

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(workersPage.incorrectId))
      }

      try {
        const startDateMoment = moment(startDate)
        const startDateTrimmed = new Date(
          Date.UTC(startDateMoment.year(), startDateMoment.month(), startDateMoment.date())
        )

        let endDateTrimmed = endDate

        if (values.endDate) {
          const endDateMoment = moment(endDate)
          endDateTrimmed = new Date(
            Date.UTC(endDateMoment.year(), endDateMoment.month(), endDateMoment.date(), 23, 59, 59)
          )
        }

        await patchWorker({
          id,
          data: {
            ...values,
            startDate: startDateTrimmed,
            endDate: endDateTrimmed
          }
        })

        message.success(t(workersPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id, startDate]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(workersPage.incorrectId))
    }

    try {
      await deleteWorker(id)

      history.push(appRoutes.workers.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  const [selectedGroup, setSelectedGroup] = useState<string>()

  const onAddToGroup = useCallback(async () => {
    try {
      await createWorkerGroup({
        workerId: id,
        groupId: selectedGroup
      })

      setSelectedGroup('')
      setGroupSearch('')
    } catch (e) {
      // ignore error
    }
  }, [selectedGroup])

  const onRemoveGroup = useCallback(
    async (groupId: string) => {
      try {
        if (!workerGroupsRes) return

        const workerGroupToDelete = workerGroupsRes.data.find(
          (workerGroup) => workerGroup.groupId === groupId
        )

        if (!workerGroupToDelete) return
        await deleteWorkerGroup(workerGroupToDelete?._id)
      } catch (e) {
        // ignore error
      }
    },
    [workerGroupsRes]
  )

  const availableGroups = useMemo(() => {
    if (!groupsRes) return []

    if (!thisWorkerGroupsRes) return groupsRes.data

    return groupsRes.data.filter(
      (group) => !thisWorkerGroupsRes.data.map((workerGroup) => workerGroup._id).includes(group._id)
    )
  }, [thisWorkerGroupsRes, groupsRes])

  const onTurnOver = useCallback(async () => {
    try {
      if (!workerDevicesRes?.data[0]) {
        message.error(t(dictionary.homePage.turnOverError))
        return
      }

      await patchWorkerDevice({
        id: workerDevicesRes?.data[0]._id,
        data: {
          returnedDate: new Date()
        }
      })

      message.success(t(dictionary.homePage.turnOver))

      await queryCache.refetchQueries('workerDevices')
      await queryCache.refetchQueries('devices')
    } catch (e) {
      console.log(e)
    }
  }, [workerDevicesRes])

  const [selectedDevice, setSelectedDevice] = useState<string>()

  const onAssignDevice = useCallback(async () => {
    try {
      await createWorkerDevice({
        workerId: id,
        deviceId: selectedDevice,
        assignedDate: new Date()
      })

      setSelectedDevice('')
      setDeviceSearch('')

      message.success(t(dictionary.homePage.assignDevice))

      await queryCache.refetchQueries('workerDevices')
      await queryCache.refetchQueries('devices')
    } catch (e) {
      console.log(e)
    }
  }, [selectedDevice])

  return {
    worker,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading',
    deleteWorkerDeviceLoading: deleteWorkerDeviceStatus === 'loading',
    availableGroups,
    thisWorkerGroups: thisWorkerGroupsRes?.data || [],
    thisWorkerGroupsLoading: findThisWorkerGroupsStatus === 'loading',
    onAddToGroup,
    setSelectedGroup,
    createWorkerGroupLoading: createWorkerGroupStatus === 'loading',
    onRemoveGroup,
    device: device,
    deviceLoading: deviceStatus === 'loading',
    onTurnOver,
    onAssignDevice,
    availableDevices: devicesRes?.data.filter((device) => !device.workerId) || [],
    setSelectedDevice,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setGroupSearch,
    setDeviceSearch
  }
}
export default useWorkerData

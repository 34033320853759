import useTable from '../../../../components/useTable'
import { generatePath, useHistory } from 'react-router-dom'
import { useFindQuery } from '../../../../query'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { ReactText, useCallback } from 'react'
import { appRoutes } from '../../../../containers/Router/routes'
import qs from 'qs'
import { LocationListViewProps } from './LocationListView'
const useLocationList: () => LocationListViewProps = () => {
  const { query, currentSort, currentPage, onTableChange } = useTable<Location>()

  const history = useHistory()
  const { data: LocationsRes, status } = useFindQuery('locations', {
    $sort: { start: '-1' },
    ...query
  })

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  const goToReport = useCallback((location: Location): void => {
    history.push(
      generatePath(appRoutes.reportsShow.routes.locationReport.path, {
        id: location._id
      })
    )
  }, [])

  return {
    handleFilter,
    currentPage,
    goToReport,
    currentSort,
    Locations: LocationsRes?.data,
    loading: status === 'loading',
    total: LocationsRes?.total || 0,
    onTableChange
  }
}

export default useLocationList

/* eslint-disable react/display-name */
import React, { FunctionComponent, HTMLAttributes, ReactText, useContext, useMemo } from 'react'
import { Table } from 'antd'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import FilterTextSearch from '../../components/FilterTextSearch'
import FilterTags from '../../components/filterTags'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Job } from '../../providers/ClientProvider/client/services/jobs/types'
import { getSelectableFilterColumn } from '../../components/getSelectableFilterColumn'
import moment from 'moment-timezone'
import JobWorkersCount from '../../components/JobWorkersCount'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  jobsPage: any
  t: any
  dictionary: any
  jobStatusSelectValues: any
}

export const Columns = (props: ColumnProps): ColumnType<Job>[] => {
  return [
    {
      title: props.t(props.jobsPage.name),
      dataIndex: 'name',
      sorter: true,
      sortOrder: props?.currentSort['name'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'name'
        })
    },
    {
      title: props.t(props.jobsPage.status),
      dataIndex: 'status',
      sorter: true,
      sortOrder: props?.currentSort['status'],
      ...getSelectableFilterColumn(
        'status',
        props.handleFilter,
        props.jobStatusSelectValues,
        props.t,
        props.dictionary
      ),
      render: (value): string => props.t(props.dictionary.jobStatus[value])
    },
    {
      title: props.t(props.jobsPage.workers),
      render: (value: string, record: Job): JSX.Element => <JobWorkersCount job={record} />
    },
    {
      title: props.t(props.jobsPage.startDate),
      dataIndex: 'start',
      sorter: true,
      sortOrder: props?.currentSort['start'],
      responsive: ['md'],
      render: (value: Date, record: Job): any =>
        moment(value).tz(record.timezone).format('HH:mm DD-MM-YYYY')
    },
    {
      title: props.t(props.jobsPage.endDate),
      dataIndex: 'end',
      sorter: true,
      sortOrder: props?.currentSort['end'],
      responsive: ['md'],
      render: (value: Date, record: Job): any =>
        moment(value).tz(record.timezone).format('HH:mm DD-MM-YYYY')
    }
  ]
}

interface JobsViewProps {
  jobs?: Job[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Job>
  handleOnRowClick: (id: string) => void
  onCreateNewLocation: () => void
  handleFilter: (value: ReactText, dataIndex: string) => void
}

const JobsView: FunctionComponent<JobsViewProps> = (props: JobsViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const jobStatusSelectValues = useMemo(
    () =>
      Object.keys(dictionary.jobStatus).map((jobStatus) => ({
        label: t((dictionary.jobStatus as any)[jobStatus]),
        value: jobStatus
      })),
    [dictionary.jobStatus]
  )

  return (
    <ListLayout
      title={t(dictionary.layouts.jobs)}
      // buttonAction={props.onCreateNewLocation}
      // buttonText={t(jobsPage.addJob)}
    >
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          dictionary: dictionary,
          jobsPage,
          jobStatusSelectValues: jobStatusSelectValues
        })}
        loading={props.loading}
        dataSource={props.jobs}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default JobsView

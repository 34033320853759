import React from 'react'

import MainMapView from './MainMapView'
import useMainMap from './useMainMap'
import { FormView } from './FormView'

const MainMap = (): JSX.Element => {
  const state = useMainMap()
  return (
    <>
      <FormView {...state.formProps} />
      <MainMapView {...state.mapProps} />
    </>
  )
}

export default MainMap

import React, { useMemo, useState } from 'react'
import { Score, JobRange } from './types'
import MainGraph from './MainGraph'
import ContextGraph from './ContextGraph'
import TopBar from './TopBar'

type ScoreGraphProps = {
  domain: [Date, Date]
  setDomain: (domain: [Date, Date]) => void
  scores: Score[]
  jobs: JobRange[]
  title: string
}

const ScoreGraph = (props: ScoreGraphProps): JSX.Element => {
  const { domain, setDomain, jobs } = props

  // zoom is the range of the graph that is currently being viewed
  const [zoom, setZoom] = useState<[Date, Date]>(domain)

  // add separators between jobs to ensure that the graph is split into sections
  const scores = useMemo(
    () =>
      props.scores
        .concat(
          jobs.map((j) => ({
            date: new Date(j.end.getTime() + 1), // 1ms after the job
            value: NaN // NaN is not displayed on the graph
          }))
        )
        // sort again so separators are in the correct place
        .sort((a, b) => a.date.getTime() - b.date.getTime()),
    [props.scores, jobs]
  )

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* domain settings, title, actions */}
      <TopBar domain={domain} setDomain={setDomain} title={props.title} zoom={zoom} />

      {/* svg being rendered to */}
      <svg
        id='graph-svg'
        width={1200}
        height={500}
        style={{ borderColor: 'white', borderStyle: 'solid' }}
      >
        {/* white background is necessary while exporting the image */}
        <rect width={1200} height={500} fill='white' />

        {/* main graph contains the score line and the job ranges */}
        <MainGraph
          width={1200}
          height={400}
          scores={scores}
          jobs={jobs}
          domain={domain}
          zoom={zoom}
          setZoom={setZoom}
        />

        {/* context graph is a zoomable overview of the main graph */}
        <g transform='translate(0, 400)'>
          <ContextGraph
            width={1200}
            height={100}
            jobs={jobs}
            domain={domain}
            zoom={zoom}
            setZoom={setZoom}
          />
        </g>
      </svg>
    </div>
  )
}

export default ScoreGraph

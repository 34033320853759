import React from 'react'

import DeviceMapView from './DeviceMapView'
import useDeviceMap from './useDeviceMap'

const DeviceMap = (): JSX.Element => {
  const state = useDeviceMap()

  return <DeviceMapView {...state} />
}

export default DeviceMap

import React from 'react'
import { Col, Row } from 'antd'
import './workerTimeline.less'
import { WorkerHourRating } from '../../providers/ClientProvider/client/services/workers/workerHourRating'

export interface Props {
  timelineData?: WorkerHourRating[]
  allHours: number[]
  checkWorkingHour: (hour: number) => string
  checkHourValue: (hour: number) => number | undefined
}

const WorkerTimelineView = (props: Props) => {
  const currentHour = new Date().getHours()
  return (
    <Row style={{ marginTop: '2rem', flexWrap: 'nowrap', overflowX: 'auto' }}>
      {props.allHours.map((hour) => {
        return (
          <Col
            className={props.checkWorkingHour(hour)}
            key={hour}
            xs={4}
            sm={3}
            md={2}
            lg={1}
            style={{ borderLeft: hour === currentHour ? 'solid 2px orange' : 'none' }}
          >
            <div className='timeline-metric__value'>{props.checkHourValue(hour)}</div>
            <div className='timeline-metric__hour'>{hour}</div>
          </Col>
        )
      })}
    </Row>
  )
}

export default WorkerTimelineView

import * as d3 from 'd3'
import React, { useEffect, useRef } from 'react'

type MainBrushProps = {
  width: number
  height: number
  domain: [Date, Date]
  xScale: d3.ScaleTime<number, number>
  zoom: [Date, Date]
  setZoom: (zoom: [Date, Date]) => void
}

const MainBrush = (props: MainBrushProps): JSX.Element => {
  const { width, height, domain, xScale, zoom, setZoom } = props
  const ref = useRef<SVGSVGElement>(null)
  useEffect(() => {
    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height]
      ])
      .on('end', (event) => {
        if (!event.sourceEvent) return
        if (!event.selection) return
        setZoom(event.selection.map(xScale.invert) as [Date, Date])
        d3.select(ref.current).call(brush.move as any, null)
      })
    d3.select(ref.current)
      .call(brush as any)
      .select('.selection')
      .attr('fill', '#e6f3ff')
      .attr('stroke', '#38d')
      .attr('stroke-width', '1')
  }, [ref, width, height, domain, xScale, zoom, setZoom])
  return <g className='brush' ref={ref}></g>
}

export default MainBrush

import { IClient } from '../../../../providers/ClientProvider/client'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { JobStatus } from '../../../../providers/ClientProvider/client/services/jobs/JobStatus.enum'

function countRequiredWorkers(job: Job): number {
  return job.workersToAssign?.reduce((acc, group) => acc + group.numberOfWorkers, 0) || 0
}

export interface ReportDataRow {
  name: string
  status: JobStatus
  requiredWorkers: number
  assignedWorkers: number
  start: Date
  end: Date
  score: number
  jobId: string
}

export async function getReportData(client: IClient, workerId: string): Promise<ReportDataRow[]> {
  // fetch all jobWorkers for the worker
  const jobWorkers = await client
    .service('jobWorkers')
    .find({
      query: {
        $limit: 1000,
        workerId,
        active: true,
        getScore: true // get the score for each jobWorker
      }
    })
    .then(({ data }) => data)

  // fetch all jobs
  const jobIds = jobWorkers.map((job) => job.jobId)
  const jobs = await client
    .service('jobs')
    .find({
      query: {
        $limit: 1000,
        countAssignedWorkers: true,
        _id: jobIds
      }
    })
    .then(({ data }) => data)
    .then((data) => data.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()))
    .then((data) => data.reverse())

  // build a map from jobId to score
  const scoresByJobId = jobWorkers.reduce(
    (acc, jobWorker) => acc.set(jobWorker.jobId, jobWorker.score || 0),
    new Map<string, number>()
  )

  // return all the rows
  return jobs.map((job) => ({
    name: job.name,
    status: job.status,
    requiredWorkers: countRequiredWorkers(job),
    assignedWorkers: job.assignedWorkers || 0,
    start: new Date(job.start),
    end: new Date(job.end),
    score: scoresByJobId.get(job._id) || 0,
    jobId: job._id
  }))
}

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export const exportToCSV = (rows: string[][], headers: string[], filename: string): void => {
  const body = rows.map((row) => row.join(',')).join('\n')
  const headerRow = headers.join(',') + '\n'
  const file = new Blob([headerRow, body], { type: 'text/plain' })
  // anchor link
  const element = document.createElement('a')
  element.href = URL.createObjectURL(file)
  element.download = filename // simulate link click
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const exportToPDF = (
  rows: string[][],
  headers: string[],
  title: string,
  filename: string
): void => {
  const doc = new jsPDF('l')
  doc.setFont('Roboto-Regular')
  doc.text(title, 148.5, 10, { align: 'center' })
  const head = [headers]
  const body = rows
  const styles = { font: 'Roboto-Regular' }
  autoTable(doc, { head, body, styles })
  doc.save(filename)
}

import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { WorkerHourRating } from '../../providers/ClientProvider/client/services/workers/workerHourRating'
import { useFindQuery } from '../../query'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { Props } from './WorkerTimelineView'

interface WorkerTimelineProps {
  worker?: Worker
}

const useWorkerTimeline = (props: WorkerTimelineProps): Props => {
  const { id } = useParams<{ id: string }>()
  const workerTimezone = 'Europe/Warsaw'

  const { currentDate, dateIn12h, dateBefore12h } = useMemo(() => {
    let currentDate = moment().tz(workerTimezone).toDate()
    currentDate = new Date(currentDate.setMinutes(0, 0, 0))

    return {
      currentDate,
      dateIn12h: new Date(currentDate.getTime() + 12 * 60 * 60000),
      dateBefore12h: new Date(currentDate.getTime() - 12 * 60 * 60000)
    }
  }, [])

  const currentHour = currentDate.getHours()

  const { data: jobWorkersFuture } = useFindQuery('jobWorkers', {
    workerId: id,
    active: true,
    end: { $gt: currentDate },
    start: { $lte: dateIn12h }
  })

  const { data: jobWorkersPast } = useFindQuery('jobWorkers', {
    workerId: id,
    active: true,
    end: { $gt: dateBefore12h },
    start: { $lt: currentDate }
  })

  const allHours: number[] = useMemo(
    () => new Array(24).fill(0).map((_, i) => (i + currentHour - 12 + 24) % 24),
    [currentHour]
  )

  const expectedWorkingHours = useMemo(() => {
    if (!jobWorkersFuture?.total) return []

    const expectedWorkingHours: boolean[] = []

    jobWorkersFuture?.data.map((jobWorker) => {
      const jobStartDate = new Date(jobWorker.start)
      const jobEndDate = new Date(jobWorker.end)

      const startHour =
        currentDate.getTime() < jobStartDate.getTime()
          ? jobStartDate.getHours()
          : currentDate.getHours() + 1

      const endHour =
        dateIn12h.getTime() > jobEndDate.getTime()
          ? jobEndDate.getHours()
          : dateIn12h.getHours() - 1

      const duration = endHour > startHour ? endHour - startHour + 1 : 24 - startHour + 1 + endHour

      for (let i = 0; i < duration; i++) {
        expectedWorkingHours[(startHour + i) % 24] = true
      }
    })

    return expectedWorkingHours
  }, [jobWorkersFuture, currentDate])

  const hoursWithStatistic: WorkerHourRating[] = useMemo(() => {
    if (!jobWorkersPast) return []

    const jobHours: number[] = []

    jobWorkersPast.data.map((jobWorker) => {
      const earliestStartTime = moment(jobWorker.start).isAfter(dateBefore12h)
        ? moment(jobWorker.start).tz(workerTimezone).toDate()
        : dateBefore12h

      earliestStartTime.setMinutes(0, 0)

      const latestEndTime = moment().tz(workerTimezone).isAfter(jobWorker.end)
        ? moment(jobWorker.end).tz(workerTimezone).toDate()
        : moment().tz(workerTimezone).toDate()

      latestEndTime.setMinutes(59, 59)

      const startHour = new Date(earliestStartTime).getHours()
      let duration = moment.duration(moment(latestEndTime).diff(earliestStartTime)).asHours()

      duration = Math.ceil(Number(duration.toFixed(2)))

      for (let i = 0; i < duration; i++) {
        jobHours.push((startHour + i) % 24)
      }
    })

    const ratingsFromExternalApi: WorkerHourRating[] = props.worker ? props.worker?.ratings : []

    const ratings = jobHours.map((hour) => {
      const externalApiValue = ratingsFromExternalApi.find((rating) => rating.hour === hour)

      if (externalApiValue) return externalApiValue

      return {
        hour: hour,
        value: undefined
      }
    })

    return ratings
  }, [props.worker, jobWorkersPast])

  const evaluatePerformance = useCallback((value: number | undefined): string => {
    if (value === undefined) return 'empty'

    if (value >= 66) {
      return 'positive'
    } else if (value < 66 && value >= 33) {
      return 'neutral'
    } else if (value < 33) {
      return 'negative'
    } else {
      return 'inactive'
    }
  }, [])

  const checkWorkingHour = useCallback(
    (hour: number): string => {
      const statisticsHour = hoursWithStatistic?.find((entry) => entry?.hour === hour)

      if (statisticsHour) return evaluatePerformance(statisticsHour.value)

      if (expectedWorkingHours[hour]) return 'unassigned'

      return 'inactive'
    },
    [hoursWithStatistic, expectedWorkingHours]
  )

  const checkHourValue = useCallback(
    (hour: number): number | undefined => {
      const workingHour = hoursWithStatistic?.find((entry) => entry.hour === hour)
      const value = workingHour?.value
      if (!value) return undefined
      return Math.round(value)
    },
    [hoursWithStatistic]
  )

  return {
    allHours,
    timelineData: hoursWithStatistic,
    // evaluatePerformance,
    checkWorkingHour,
    checkHourValue
  }
}

export default useWorkerTimeline

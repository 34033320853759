import React from 'react'
import { useWorkerChart } from './useWorkerChart'
import { WorkerChartView } from './WorkerChartView'

const WorkerChart = (): JSX.Element => {
  const state = useWorkerChart()
  return <WorkerChartView {...state} />
}

export default WorkerChart

import { Form, message } from 'antd'
import { useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'

import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useGetQuery, useDeleteMutation, usePatchMutation } from '../../../../query'

const useDeviceData = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: device } = useGetQuery('devices', id)

  const [deleteDevice, { status: deleteStatus }] = useDeleteMutation('devices')

  const [patchDevice, { status: patchStatus }] = usePatchMutation('devices')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(devicesPage.incorrectId))
      }

      try {
        await patchDevice({
          id,
          data: values
        })

        message.success(t(devicesPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(devicesPage.incorrectId))
    }

    try {
      await deleteDevice(id)

      history.push(appRoutes.devices.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    device,
    form,
    onFinish,
    onDelete,
    patchLoading: patchStatus === 'loading',
    deleteLoading: deleteStatus === 'loading'
  }
}
export default useDeviceData

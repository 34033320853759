import React, { useCallback } from 'react'
import moment from 'moment'
import { saveToPNG } from './save'
import { Typography, Button, DatePicker, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'

export type TopBarProps = {
  domain: [Date, Date]
  setDomain: (domain: [Date, Date]) => void
  title: string
  zoom: [Date, Date]
}

const TopBar = ({ domain, setDomain, title, zoom }: TopBarProps): JSX.Element => {
  const setDomain1d = useCallback(
    () => setDomain([moment().subtract(1, 'day').toDate(), moment().toDate()]),
    []
  )
  const setDomain3d = useCallback(
    () => setDomain([moment().subtract(3, 'day').toDate(), moment().toDate()]),
    []
  )
  const setDomain7d = useCallback(
    () => setDomain([moment().subtract(7, 'day').toDate(), moment().toDate()]),
    []
  )
  const saveAsPng = useCallback(() => {
    const start = moment(zoom[0]).format('YYYY_MM_DD')
    const end = moment(zoom[1]).format('YYYY_MM_DD')
    const filename = `${title} ${start}-${end}.png`
    saveToPNG('graph-svg', filename, { x: 0, y: 0, w: 1200, h: 400 })
  }, [zoom])
  return (
    <div
      style={{
        width: '1200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 20
      }}
    >
      <Typography.Title level={4} style={{ flexGrow: 1 }}>
        {title}
      </Typography.Title>
      <Button onClick={setDomain1d}>1d</Button>
      <Button onClick={setDomain3d}>3d</Button>
      <Button onClick={setDomain7d}>7d</Button>
      <DatePicker.RangePicker
        value={[moment(domain[0]), moment(domain[1])]}
        onCalendarChange={(range): void => {
          if (!range || !range[0] || !range[1]) return
          setDomain([range[0].toDate(), range[1].toDate()])
        }}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <Button onClick={saveAsPng}>Zapisz jako PNG</Button>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <Button>
          Więcej
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  )
}

export default TopBar

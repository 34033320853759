import { DatePicker, Menu } from 'antd'
import React, { FunctionComponent, useContext, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { RangeValue } from 'rc-picker/lib/interface'

import { reportShowRoutes } from '../../containers/Router/routes'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './reportShowLayout.less'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface ReportShowLayoutViewProps {
  children?: React.ReactNode
  menuSelectedKey?: string
}

const ReportShowLayoutView: FunctionComponent<ReportShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts
  const reportsPage = dictionary.reportsPage
  return (
    <ContentLoader loading={false}>
      {/*todo*/}
      <SubpageLayout title={t(layouts.reports)}>
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={reportShowRoutes.workers.path}>
            <Link to={generatePath(reportShowRoutes.workers.path)} replace>
              {/*todo: change id*/}
              {t(reportsPage.workers)}
            </Link>
          </Menu.Item>

          <Menu.Item key={reportShowRoutes.managers.path}>
            <Link to={generatePath(reportShowRoutes.managers.path)} replace>
              {/*todo: change id*/}
              {t(reportsPage.managers)}
            </Link>
          </Menu.Item>

          <Menu.Item key={reportShowRoutes.locations.path}>
            <Link to={generatePath(reportShowRoutes.locations.path)} replace>
              {/*todo: change id*/}
              {t(reportsPage.locations)}
            </Link>
          </Menu.Item>
        </Menu>
        {props.children}
      </SubpageLayout>
    </ContentLoader>
  )
}

export default ReportShowLayoutView
